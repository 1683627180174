.benefits {
}

.benefit__item {
  margin-bottom: 1rem;
}

// .benefit__item--highlighted {
//   .benefit__content {
//     box-shadow: 20px 20px 12px rgba(0, 0, 0, 0.07);
//     background-color: $color-blue;
//     border-color: $color-blue;
//     @include bp(medium) {
//       transform: translateY(-30px);
//     }
//   }
// }

.benefit__content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 1rem 1rem;
  min-height: 200px;
  border: 1px solid #3e414d;
  border-radius: 5px;
  overflow: hidden;
  transition: all 250ms ease-out;
  cursor: pointer;
  &:focus,
  &:hover {
    box-shadow: 20px 20px 12px rgba(0, 0, 0, 0.07);
    background-color: $color-blue;
    border-color: $color-blue;
    @include bp(medium) {
      transform: translateY(-30px);
    }
  }
  svg {
    @include font-size(60px);
  }
}

.benefit__text {
  @include font-size(18px);
  line-height: 1.4;
  color: $color-white-light;
}
