/* Image helpers */

.img {
  display: block;
}

.img--full {
  width: 100%;
}

.img--responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img--cover {
  height: 100%;
  object-fit: cover;
}

.img--contain {
  height: 100%;
  object-fit: contain;
}

.bg-image-hover,
.img-hover {
  display: block;
  overflow: hidden;
  &:focus,
  &:hover {
    > img {
      transform: scale(1.05, 1.05);
    }
  }
  img {
    transition: transform 400ms ease-out;
    will-change: transform;
  }
}

.img-flex-contain,
.img-flex-cover {
  display: flex;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.img-flex-cover {
  img {
    object-fit: cover;
  }
}

.img-flex-contain {
  img {
    object-fit: contain;
  }
}

.bg-image,
.bg-rectangle {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.about-image-control {
  @include bp(rev-small) {
    margin: 0 auto;
    max-width: 300px;
  }
  @include bp(rev-xsmall) {
    margin: 0 auto;
    max-width: 250px;
  }
}

.image-freeze {
  padding-right: 30px;
  @include bp(medium) {
    padding-right: 0;
  }
}

.image-air-condition {
  position: absolute;
  right: 0;
  top: calc(100% - 60px);
  max-width: 280px;
  @include bp(xssmall) {
    max-width: 330px;
    top: calc(100% - 100px);
  }
  @include bp(xsmall) {
    right: -10px;
    top: calc(100% - 150px);
    max-width: 500px;
  }
  @include bp(small) {
    right: -10px;
    max-width: 500px;
  }
  @include bp(medium) {
    right: -30px;
    max-width: 360px;
    top: calc(100% - 80px);
  }
  @include bp(large) {
    right: -50px;
    max-width: 100%;
    top: calc(100% - 80px);
  }
  @include bp(large) {
    top: calc(100% - 150px);
  }
}

.img-shadow {
  filter: drop-shadow(0 20px 20px rgba(0, 0, 0, 0.3));
}

.img-shadow--big {
  filter: drop-shadow(30px 30px 60px rgba(0, 0, 0, 0.45));
}

.bg-gradient-hero {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 390px;
  height: 240px;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: radial-gradient(
      closest-side at 48% 47%,
      #163c5c 0%,
      #163b59 52%,
      rgba(255, 255, 255, 0) 100%
    )
    0% 0% no-repeat padding-box;
  @include bp(xsmall) {
    width: 768px;
    height: 363px;
  }
  @include bp(medium) {
    width: 1920px;
    height: 908px;
    background: radial-gradient(
        closest-side at 48% 47%,
        #163c5c 0%,
        rgba(255, 255, 255, 0) 100%
      )
      0% 0% no-repeat padding-box;
  }
}

.bg-gradient-about {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 390px;
  height: 240px;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: radial-gradient(
      closest-side at 48% 47%,
      #163c5c 0%,
      #163b59 52%,
      rgba(255, 255, 255, 0) 100%
    )
    0% 0% no-repeat padding-box;
  @include bp(xssmall) {
    width: 510px;
    height: 430px;
  }
  @include bp(xsmall) {
    width: 908px;
    height: 593px;
    background: radial-gradient(
        closest-side at 48% 47%,
        #163c5c 0%,
        #163b59 52%,
        rgba(255, 255, 255, 0) 100%
      )
      0% 0% no-repeat padding-box;
  }
  @include bp(medium) {
    width: 1920px;
    height: 908px;
    background: radial-gradient(
        closest-side at 29% 40%,
        #214d71 0%,
        rgba(255, 255, 255, 0) 100%
      )
      0% 0% no-repeat padding-box;
  }
}

.bg-gradient-remote-control {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 390px;
  height: 390px;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: radial-gradient(
      closest-side at 48% 47%,
      #163c5c 0%,
      #163b59 52%,
      rgba(255, 255, 255, 0) 100%
    )
    0% 0% no-repeat padding-box;
  @include bp(xsmall) {
    width: 808px;
    height: 580px;
    background: radial-gradient(
        closest-side at 50% 50%,
        #1f4665 0%,
        rgba(255, 255, 255, 0) 100%
      )
      0% 0% no-repeat padding-box;
  }
  @include bp(medium) {
    width: 875px;
    height: 908px;
    background: radial-gradient(
        closest-side at 56% 48%,
        #1f4665 0%,
        rgba(255, 255, 255, 0) 100%
      )
      0% 0% no-repeat padding-box;
  }
}

.bg-gradient-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 390px;
  height: 240px;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: radial-gradient(
      closest-side at 48% 47%,
      #163c5c 0%,
      #163b59 52%,
      rgba(255, 255, 255, 0) 100%
    )
    0% 0% no-repeat padding-box;
  display: none;
  @include bp(xssmall) {
    top: 40%;
    width: 510px;
    height: 430px;
  }
  @include bp(xsmall) {
    top: 40%;
    width: 908px;
    height: 593px;
    background: radial-gradient(
        closest-side at 50% 50%,
        #1f4665 0%,
        rgba(255, 255, 255, 0) 100%
      )
      0% 0% no-repeat padding-box;
  }
  @include bp(small) {
    display: block;
  }
  @include bp(medium) {
    top: 50%;
    width: 875px;
    height: 908px;
    background: radial-gradient(
        closest-side at 48% 47%,
        #163c5c 0%,
        rgba(255, 255, 255, 0) 100%
      )
      0% 0% no-repeat padding-box;
  }
  @include bp(large) {
    left: 70%;
  }
}

.bg-gradient-showcase {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 390px;
  height: 240px;
  z-index: -1;
  transform: translate(-50%, -50%);
  display: none;
  @include bp(small) {
    display: block;
    width: 878px;
    height: 530px;
    background: radial-gradient(
        closest-side at 48% 47%,
        #163c5c 0%,
        #163b59 52%,
        transparent 100%
      )
      0% 0% no-repeat padding-box;
  }
  @include bp(medium) {
    top: 50%;
    width: 1920px;
    height: 788px;
    background: radial-gradient(
        closest-side at 48% 47%,
        #214d71 0%,
        transparent 100%
      )
      0% 0% no-repeat padding-box;
  }
  @include bp(large) {
    width: 1920px;
    height: 908px;
  }
}

.bg-gradient-form {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 390px;
  height: 809px;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: radial-gradient(
      closest-side at 48% 47%,
      #163c5c 0%,
      transparent 100%
    )
    0% 0% no-repeat padding-box;
  @include bp(xsmall) {
    width: 768px;
    height: 410px;
    background: radial-gradient(
        closest-side at 48% 47%,
        #163c5c 0%,
        transparent 100%
      )
      0% 0% no-repeat padding-box;
  }
  @include bp(medium) {
    width: 1920px;
    height: 908px;
    background: radial-gradient(
        closest-side at 48% 47%,
        #163c5c 0%,
        transparent 100%
      )
      0% 0% no-repeat padding-box;
  }
}
