.header {
}

.header__flex {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 20px;
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  width: 100%;
}

.header__link {
  display: inline-block;
  padding: 0.6rem 0;
  color: #ffffff;
  font-weight: 400;
  @include font-size(14px);
  @include bp(xsmall) {
    @include font-size(14px);
  }
  @include bp(small) {
    @include font-size(16px);
  }
  &:hover {
    color: $color-blue;
  }
}
