/* Base settings */

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  transition: color 0.15s, background 0.15s, border 0.15s, opacity 0.15s;
}

*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

::-moz-selection {
  color: #fff;
  background-color: $color-blue;
}

::selection {
  color: #fff;
  background-color: $color-blue;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: $color-gray-light;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: $color-blue;
}

::-webkit-scrollbar-thumb:hover {
  background: darken($color-blue, 5%);
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

html {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  font-size: 100%;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 310px;
  margin: 0;
  color: $color-white-light;
  background-color: #121212;
  font-family: $font-primary;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5;
}

em,
i,
.italic {
  font-style: italic;
}

strong,
.strong {
  font-weight: 700;
}

small,
.small {
  @include font-size(12px);
}
