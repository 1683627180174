/* Web fonts */
/* Animate helpers */
@-webkit-keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  animation-name: spinner-rotate;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border: 10px solid #4591cf;
  border-right-color: transparent;
  border-radius: 50%;
}

/* Typography */
.typography p,
.typography li,
.typography td,
.typography th {
  line-height: 1.5em;
}

.typography p,
.typography ul,
.typography ol,
.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6,
.typography table,
.typography fieldset,
.typography blockquote,
.typography iframe {
  margin-bottom: 1rem;
}

.typography h1 {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 26px;
  font-size: 1.625rem;
}

@media (min-width: 771px) {
  .typography h1 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

.typography h2 {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 24px;
  font-size: 1.5rem;
}

@media (min-width: 771px) {
  .typography h2 {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

.typography h3 {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 22px;
  font-size: 1.375rem;
}

@media (min-width: 771px) {
  .typography h3 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

.typography h4 {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 20px;
  font-size: 1.25rem;
}

@media (min-width: 771px) {
  .typography h4 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

.typography h5 {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 771px) {
  .typography h5 {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.typography h6 {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 771px) {
  .typography h6 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.typography a {
  text-decoration: underline;
  color: #000000;
}

.typography a:focus, .typography a:hover {
  text-decoration: underline;
}

.typography p {
  display: block;
  margin-top: 0;
  margin-bottom: 2.5rem;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #000000;
  font-size: 16px;
  font-size: 1rem;
}

.typography strong {
  font-weight: 700;
  color: #000000;
}

.typography img {
  max-width: 100%;
  margin-bottom: 2rem;
}

.typography ul,
.typography ol {
  margin-bottom: 2.5rem;
  padding-left: 0;
  list-style: none;
}

.typography ul li,
.typography ol li {
  position: relative;
  margin-bottom: 0.3rem;
  padding-left: 3rem;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #000000;
  font-size: 16px;
  font-size: 1rem;
}

.typography ul li:before {
  position: absolute;
  top: 9px;
  left: 3px;
  display: block;
  width: 6px;
  height: 6px;
  content: '';
  color: #000000;
  border-radius: 100%;
  background-color: #000000;
}

.typography ol {
  list-style-position: inside;
  counter-reset: item;
}

.typography ol li:before {
  position: absolute;
  top: 1px;
  left: 0;
  display: inline-block;
  line-height: 1.4;
  content: counter(item) ".";
  counter-increment: item;
  color: #000000;
  font-size: 16px;
  font-size: 1rem;
}

.typography figure {
  margin: 0;
  margin-bottom: 2rem;
}

.typography figure img {
  margin: 0;
}

.typography figcaption {
  margin-top: 0.6rem;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  font-size: 0.75rem;
}

/* Form base */
.form__input, .form__textarea {
  width: 100%;
  height: 45px;
  padding: 0.2rem 1rem;
  border: 1px solid #656871;
  background-color: transparent;
  color: #656871;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 5px;
  outline: none !important;
  -webkit-appearance: none;
}

@media (min-width: 992px) {
  .form__input, .form__textarea {
    padding: 0.2rem 2.5rem;
    height: 80px;
  }
}

.form__input:not(:placeholder-shown), .form__textarea:not(:placeholder-shown) {
  border-color: #ffffff;
}

.form__input:focus, .form__textarea:focus {
  border-color: #ffffff;
}

.form__input:-webkit-autofill, .form__textarea:-webkit-autofill, .form__input:-webkit-autofill:hover, .form__textarea:-webkit-autofill:hover, .form__input:-webkit-autofill:focus, .form__textarea:-webkit-autofill:focus {
  border: 1px solid #656871;
  -webkit-text-fill-color: #656871;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form__input-ext {
  border: 0;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}

.form__textarea {
  line-height: 1.5rem;
  resize: none;
  height: 270px;
  padding: 1.5rem 1rem;
}

@media (min-width: 992px) {
  .form__textarea {
    padding: 2rem 2.5rem;
    height: 370px;
  }
}

.form__select {
  width: 100%;
  height: 60px;
  padding: 0.2rem 1.5rem;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #000000;
  border: 2px solid #000000;
  outline: none;
  background-color: #ffffff;
  font-size: 16px;
  font-size: 1rem;
  -webkit-appearance: value;
  -moz-appearance: value;
  -ms-appearance: value;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.form__icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 60px;
  height: 60px;
  font-size: 22px;
  font-size: 1.375rem;
  color: #000000;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.form__icon-block .form__input {
  padding-right: 60px;
}

.form__label {
  display: block;
  margin: 0 0 10px;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 300;
  color: #cfd3de;
  font-size: 16px;
  font-size: 1rem;
}

@media (min-width: 576px) {
  .form__label {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (min-width: 992px) {
  .form__label {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.form__required {
  font-size: 12px;
  font-size: 0.75rem;
}

@media (min-width: 576px) {
  .form__required {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media (min-width: 992px) {
  .form__required {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.form__field {
  position: relative;
}

.form__field--small {
  margin-bottom: 0.5rem;
}

.form__field--medium {
  margin-bottom: 1.2rem;
}

@media (min-width: 992px) {
  .form__field--medium {
    margin-bottom: 2rem;
  }
}

.form__field--big {
  margin-bottom: 2rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Placeholder helpers */
input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #656871;
}

input::-moz-input-placeholder,
select::-moz-input-placeholder,
textarea::-moz-input-placeholder {
  color: #656871;
}

input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #656871;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
  opacity: 1;
}

input:focus::-moz-input-placeholder,
textarea:focus::-moz-input-placeholder {
  color: transparent;
  opacity: 1;
}

input:focus:-moz-input-placeholder,
textarea:focus:-moz-input-placeholder {
  color: transparent;
  opacity: 1;
}

/* Form validation */
.has-field-error .form__field-message {
  display: block;
}

.has-field-error.select2-container .select2-selection--single,
.has-field-error.select-classic .select2-container .select2-selection--multiple, .has-field-error.form__textarea, .has-field-error.form__input {
  border-color: #ea4335 !important;
}

.has-field-error + .select2-container .select2-selection--single,
.has-field-error + .select-classic .select2-container .select2-selection--multiple {
  border-color: #ea4335 !important;
}

.has-field-success.select2-container .select2-selection--single,
.has-field-success.select-classic .select2-container .select2-selection--multiple, .has-field-success.form__textarea, .has-field-success.form__input {
  border-color: #55a954 !important;
}

.has-field-success + .select2-container .select2-selection--single,
.has-field-success + .select-classic .select2-container .select2-selection--multiple {
  border-color: #55a954 !important;
}

.parsley-error.select2-container .select2-selection--single,
.parsley-error.select-classic .select2-container .select2-selection--multiple, .parsley-error.form__textarea, .parsley-error.form__input,
.has-error.select2-container .select2-selection--single,
.has-error.select-classic .select2-container .select2-selection--multiple,
.has-error.form__textarea,
.has-error.form__input {
  border-color: #ea4335 !important;
}

.parsley-error + .select2-container .select2-selection--single,
.parsley-error + .select-classic .select2-container .select2-selection--multiple,
.has-error + .select2-container .select2-selection--single,
.has-error + .select-classic .select2-container .select2-selection--multiple {
  border-color: #ea4335 !important;
}

.has-field-success.select2-container .select2-selection--single,
.has-field-success.select-classic .select2-container .select2-selection--multiple, .has-field-success.form__textarea, .has-field-success.form__input,
.parsley-success.select2-container .select2-selection--single,
.parsley-success.select-classic .select2-container .select2-selection--multiple,
.parsley-success.form__textarea,
.parsley-success.form__input {
  border-color: #55a954 !important;
}

.has-field-success + .select2-container .select2-selection--single,
.has-field-success + .select-classic .select2-container .select2-selection--multiple,
.parsley-success + .select2-container .select2-selection--single,
.parsley-success + .select-classic .select2-container .select2-selection--multiple {
  border-color: #55a954 !important;
}

.form__field-message {
  display: none;
  margin-top: 5px;
  color: #870000;
  text-align: right;
  font-size: 14px;
  font-size: 0.875rem;
}

.parsley-errors-list {
  color: #ea4335;
  text-align: right;
  font-size: 14px;
  font-size: 0.875rem;
}

.parsley-errors-list li {
  display: block;
  margin-top: 5px;
}

/* Buttons helper */
.btn {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0;
  padding: 0.8em 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  outline: none !important;
  background: #000000;
  text-align: center;
  transition: background-color 250ms ease;
  border-radius: 5px;
  font-size: 16px;
  font-size: 1rem;
  -webkit-appearance: none;
}

@media (min-width: 576px) {
  .btn {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (min-width: 771px) {
  .btn {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.btn, .btn:visited, .btn:active, .btn:focus {
  color: white;
}

.btn:hover {
  transition: background-color 250ms ease;
}

@media (min-width: 992px) {
  .btn:hover {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }
}

.btn--responsive {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
}

.btn--font-size-xsmall {
  font-size: 12px;
  font-size: 0.75rem;
}

.btn--font-size-small {
  font-size: 14px;
  font-size: 0.875rem;
}

.btn--font-size-medium {
  font-size: 16px;
  font-size: 1rem;
}

.btn--font-size-big {
  font-size: 18px;
  font-size: 1.125rem;
}

.btn--font-size-large {
  font-size: 20px;
  font-size: 1.25rem;
}

.btn--size-small {
  padding: 0.6em 1.2rem;
}

.btn--size-medium {
  padding: 1.2em 1.5rem;
}

.btn--size-big {
  padding: 2.2em 2rem;
}

.btn--min-width-xsmall {
  min-width: 120px;
}

.btn--min-width-small {
  min-width: 160px;
}

.btn--min-width-medium {
  min-width: 280px;
}

.btn--min-width-big {
  min-width: 300px;
}

.btn--black-fill {
  color: #ffffff;
  border: 2px solid transparent;
  background-color: #000000;
}

.btn--black-fill, .btn--black-fill:visited, .btn--black-fill:active, .btn--black-fill:focus {
  color: #ffffff;
}

.btn--black-fill:hover {
  color: #000000;
  background-color: #ffffff;
}

.btn--black-fill:hover .btn__icon {
  color: #000000;
}

.btn--black-fill .btn__icon {
  color: #ffffff;
}

.btn--blue-fill {
  color: #ffffff;
  border: 2px solid transparent;
  background-color: #4591cf;
}

.btn--blue-fill, .btn--blue-fill:visited, .btn--blue-fill:active, .btn--blue-fill:focus {
  color: #ffffff;
}

.btn--blue-fill:hover {
  color: #ffffff;
  background-color: #2e77b3;
}

.btn--blue-fill:hover .btn__icon {
  color: #ffffff;
}

.btn--blue-fill .btn__icon {
  color: #ffffff;
}

.btn--white-outline {
  color: #ffffff;
  border: 1px solid #4591cf;
  background-color: transparent;
}

.btn--white-outline, .btn--white-outline:visited, .btn--white-outline:active, .btn--white-outline:focus {
  color: #ffffff;
}

.btn--white-outline:hover {
  color: #ffffff;
  border: 1px solid #4591cf;
  background-color: #4591cf;
}

/* Color helpers */
.bg-black {
  background-color: #000000;
}

.bg-purple {
  background-color: #4591cf;
}

.bg-gray-light {
  background-color: #656871;
}

.txt-white {
  color: #ffffff;
}

.txt-black {
  color: #000000;
}

.h--white h1,
.h--white .alfa,
.h--white h2,
.h--white .beta,
.h--white h3,
.h--white .gamma,
.h--white h4,
.h--white .delta,
.h--white h5,
.h--white .epsilon,
.h--white h6,
.h--white .zeta {
  color: #ffffff;
}

.h--purple h1,
.h--purple .alfa,
.h--purple h2,
.h--purple .beta,
.h--purple h3,
.h--purple .gamma,
.h--purple h4,
.h--purple .delta,
.h--purple h5,
.h--purple .epsilon,
.h--purple h6,
.h--purple .zeta {
  color: #4591cf;
}

.p--white p {
  color: #ffffff;
}

.p--black p {
  color: #000000;
}

.p--blue p {
  color: #4591cf;
}

ul.list-style.ul--white li,
.ul--white li {
  color: #ffffff;
}

ul.list-style.ul--white li:before,
.ul--white li:before {
  background-color: #ffffff;
}

ol.list-style.ol-white li,
.ol-white li {
  color: #ffffff;
}

ol.list-style.ol-white li:before,
.ol-white li:before {
  color: #ffffff;
}

/* Gutter helpers */
.no-gutter {
  margin: 0;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.gutter-4.row {
  margin-right: -2px;
  margin-left: -2px;
}

.gutter-4 > [class*="col-"],
.gutter-4 > [class*=" col-"] {
  padding-right: 2px;
  padding-left: 2px;
}

.gutter-6.row {
  margin-right: -3px;
  margin-left: -3px;
}

.gutter-6 > [class*="col-"],
.gutter-6 > [class*=" col-"] {
  padding-right: 3px;
  padding-left: 3px;
}

.gutter-10.row {
  margin-right: -5px;
  margin-left: -5px;
}

.gutter-10 > [class*="col-"],
.gutter-10 > [class*=" col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.gutter-20.row {
  margin-right: -10px;
  margin-left: -10px;
}

.gutter-20 > [class*="col-"],
.gutter-20 > [class*=" col-"] {
  padding-right: 10px;
  padding-left: 10px;
}

.gutter-30.row {
  margin-right: -15px;
  margin-left: -15px;
}

.gutter-30 > [class*="col-"],
.gutter-30 > [class*=" col-"] {
  padding-right: 15px;
  padding-left: 15px;
}

.gutter-40.row {
  margin-right: -20px;
  margin-left: -20px;
}

.gutter-40 > [class*="col-"],
.gutter-40 > [class*=" col-"] {
  padding-right: 20px;
  padding-left: 20px;
}

.gutter-50.row {
  margin-right: -25px;
  margin-left: -25px;
}

.gutter-50 > [class*="col-"],
.gutter-50 > [class*=" col-"] {
  padding-right: 25px;
  padding-left: 25px;
}

.gutter-60.row {
  margin-right: -30px;
  margin-left: -30px;
}

.gutter-60 > [class*="col-"],
.gutter-60 > [class*=" col-"] {
  padding-right: 30px;
  padding-left: 30px;
}

.gutter-sm-80.row {
  margin-right: -40px;
  margin-left: -40px;
}

.gutter-sm-80 > [class*="col-"],
.gutter-sm-80 > [class*=" col-"] {
  padding-right: 40px;
  padding-left: 40px;
}

.gutter-sm-100.row {
  margin-right: -50px;
  margin-left: -50px;
}

.gutter-sm-100 > [class*="col-"],
.gutter-sm-100 > [class*=" col-"] {
  padding-right: 50px;
  padding-left: 50px;
}

@media (min-width: 576px) {
  .gutter-xs-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-xs-10 > [class*="col-"],
  .gutter-xs-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-xs-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-xs-20 > [class*="col-"],
  .gutter-xs-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-xs-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-xs-30 > [class*="col-"],
  .gutter-xs-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-xs-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-xs-40 > [class*="col-"],
  .gutter-xs-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-xs-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-xs-50 > [class*="col-"],
  .gutter-xs-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-xs-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-xs-60 > [class*="col-"],
  .gutter-xs-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-xs-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-xs-100 > [class*="col-"],
  .gutter-xs-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 771px) {
  .gutter-sm-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-sm-10 > [class*="col-"],
  .gutter-sm-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-sm-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-sm-20 > [class*="col-"],
  .gutter-sm-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-sm-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-sm-30 > [class*="col-"],
  .gutter-sm-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-sm-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-sm-40 > [class*="col-"],
  .gutter-sm-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-sm-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-sm-50 > [class*="col-"],
  .gutter-sm-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-sm-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-sm-60 > [class*="col-"],
  .gutter-sm-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-sm-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-sm-100 > [class*="col-"],
  .gutter-sm-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 992px) {
  .no-md-gutter {
    margin: 0;
  }
  .no-md-gutter > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  .gutter-md-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-md-10 > [class*="col-"],
  .gutter-md-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-md-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-md-20 > [class*="col-"],
  .gutter-md-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-md-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-md-30 > [class*="col-"],
  .gutter-md-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-md-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-md-40 > [class*="col-"],
  .gutter-md-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-md-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-md-50 > [class*="col-"],
  .gutter-md-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-md-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-md-60 > [class*="col-"],
  .gutter-md-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-md-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-md-100 > [class*="col-"],
  .gutter-md-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 1200px) {
  .gutter-lg-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-lg-10 > [class*="col-"],
  .gutter-lg-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-lg-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-lg-20 > [class*="col-"],
  .gutter-lg-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-lg-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-lg-30 > [class*="col-"],
  .gutter-lg-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-lg-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-lg-40 > [class*="col-"],
  .gutter-lg-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-lg-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-lg-50 > [class*="col-"],
  .gutter-lg-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-lg-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-lg-60 > [class*="col-"],
  .gutter-lg-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-lg-80.row {
    margin-right: -40px;
    margin-left: -40px;
  }
  .gutter-lg-80 > [class*="col-"],
  .gutter-lg-80 > [class*=" col-"] {
    padding-right: 40px;
    padding-left: 40px;
  }
  .gutter-lg-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-lg-100 > [class*="col-"],
  .gutter-lg-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 1440px) {
  .gutter-xl-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-xl-30 > [class*="col-"],
  .gutter-xl-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-xl-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-xl-40 > [class*="col-"],
  .gutter-xl-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-xl-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-xl-60 > [class*="col-"],
  .gutter-xl-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-xl-80.row {
    margin-right: -40px;
    margin-left: -40px;
  }
  .gutter-xl-80 > [class*="col-"],
  .gutter-xl-80 > [class*=" col-"] {
    padding-right: 40px;
    padding-left: 40px;
  }
  .gutter-xl-120.row {
    margin-right: -60px;
    margin-left: -60px;
  }
  .gutter-xl-120 > [class*="col-"],
  .gutter-xl-120 > [class*=" col-"] {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media (min-width: 1600px) {
  .gutter-xxl-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-xxl-30 > [class*="col-"],
  .gutter-xxl-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-xxl-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-xxl-60 > [class*="col-"],
  .gutter-xxl-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-xxl-120.row {
    margin-right: -60px;
    margin-left: -60px;
  }
  .gutter-xxl-120 > [class*="col-"],
  .gutter-xxl-120 > [class*=" col-"] {
    padding-right: 60px;
    padding-left: 60px;
  }
  .gutter-xxl-180.row {
    margin-right: -90px;
    margin-left: -90px;
  }
  .gutter-xxl-180 > [class*="col-"],
  .gutter-xxl-180 > [class*=" col-"] {
    padding-right: 90px;
    padding-left: 90px;
  }
}

@media (min-width: 1800px) {
  .gutter-xxxl-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-xxxl-30 > [class*="col-"],
  .gutter-xxxl-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-xxxl-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-xxxl-60 > [class*="col-"],
  .gutter-xxxl-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
}

/* Hidden content */
.hidden-content {
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity 250ms ease;
}

.hidden-content.is-active {
  height: auto;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  transition: opacity 250ms ease;
}

@media (max-width: 575px) {
  .hidden-xs-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 250ms ease;
  }
  .hidden-xs-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 250ms ease;
  }
}

@media (max-width: 770px) {
  .hidden-sm-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 250ms ease;
  }
  .hidden-sm-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 250ms ease;
  }
}

@media (max-width: 991px) {
  .hidden-md-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 250ms ease;
  }
  .hidden-md-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 250ms ease;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 250ms ease;
  }
  .hidden-lg-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 250ms ease;
  }
}

/* Hidden class */
.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-visible {
  overflow: visible;
}

/* Icon helpers */
.icon-svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon--size-12 svg {
  font-size: 12px;
  font-size: 0.75rem;
}

.icon--size-14 svg {
  font-size: 14px;
  font-size: 0.875rem;
}

.icon--size-16 svg {
  font-size: 16px;
  font-size: 1rem;
}

.icon--size-18 svg {
  font-size: 18px;
  font-size: 1.125rem;
}

.icon--size-20 svg {
  font-size: 20px;
  font-size: 1.25rem;
}

.icon--size-22 svg {
  font-size: 22px;
  font-size: 1.375rem;
}

.icon--size-24 svg {
  font-size: 24px;
  font-size: 1.5rem;
}

.icon--size-26 svg {
  font-size: 26px;
  font-size: 1.625rem;
}

.icon--size-28 svg {
  font-size: 28px;
  font-size: 1.75rem;
}

.icon--size-30 svg {
  font-size: 30px;
  font-size: 1.875rem;
}

.icon--size-60 svg {
  font-size: 60px;
  font-size: 3.75rem;
}

.icon---white svg {
  fill: #ffffff;
}

.icon---black svg {
  fill: #000000;
}

.icon---purple svg {
  fill: #4591cf;
}

/* Image helpers */
.img {
  display: block;
}

.img--full {
  width: 100%;
}

.img--responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img--cover {
  height: 100%;
  object-fit: cover;
}

.img--contain {
  height: 100%;
  object-fit: contain;
}

.bg-image-hover,
.img-hover {
  display: block;
  overflow: hidden;
}

.bg-image-hover:focus > img, .bg-image-hover:hover > img,
.img-hover:focus > img,
.img-hover:hover > img {
  transform: scale(1.05, 1.05);
}

.bg-image-hover img,
.img-hover img {
  transition: transform 400ms ease-out;
  will-change: transform;
}

.img-flex-contain,
.img-flex-cover {
  display: flex;
}

.img-flex-contain img,
.img-flex-cover img {
  display: block;
  width: 100%;
  height: 100%;
}

.img-flex-cover img {
  object-fit: cover;
}

.img-flex-contain img {
  object-fit: contain;
}

.bg-image,
.bg-rectangle {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 770px) {
  .about-image-control {
    margin: 0 auto;
    max-width: 300px;
  }
}

@media (max-width: 575px) {
  .about-image-control {
    margin: 0 auto;
    max-width: 250px;
  }
}

.image-freeze {
  padding-right: 30px;
}

@media (min-width: 992px) {
  .image-freeze {
    padding-right: 0;
  }
}

.image-air-condition {
  position: absolute;
  right: 0;
  top: calc(100% - 60px);
  max-width: 280px;
}

@media (min-width: 370px) {
  .image-air-condition {
    max-width: 330px;
    top: calc(100% - 100px);
  }
}

@media (min-width: 576px) {
  .image-air-condition {
    right: -10px;
    top: calc(100% - 150px);
    max-width: 500px;
  }
}

@media (min-width: 771px) {
  .image-air-condition {
    right: -10px;
    max-width: 500px;
  }
}

@media (min-width: 992px) {
  .image-air-condition {
    right: -30px;
    max-width: 360px;
    top: calc(100% - 80px);
  }
}

@media (min-width: 1200px) {
  .image-air-condition {
    right: -50px;
    max-width: 100%;
    top: calc(100% - 80px);
  }
}

@media (min-width: 1200px) {
  .image-air-condition {
    top: calc(100% - 150px);
  }
}

.img-shadow {
  filter: drop-shadow(0 20px 20px rgba(0, 0, 0, 0.3));
}

.img-shadow--big {
  filter: drop-shadow(30px 30px 60px rgba(0, 0, 0, 0.45));
}

.bg-gradient-hero {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 390px;
  height: 240px;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: radial-gradient(closest-side at 48% 47%, #163c5c 0%, #163b59 52%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box;
}

@media (min-width: 576px) {
  .bg-gradient-hero {
    width: 768px;
    height: 363px;
  }
}

@media (min-width: 992px) {
  .bg-gradient-hero {
    width: 1920px;
    height: 908px;
    background: radial-gradient(closest-side at 48% 47%, #163c5c 0%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box;
  }
}

.bg-gradient-about {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 390px;
  height: 240px;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: radial-gradient(closest-side at 48% 47%, #163c5c 0%, #163b59 52%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box;
}

@media (min-width: 370px) {
  .bg-gradient-about {
    width: 510px;
    height: 430px;
  }
}

@media (min-width: 576px) {
  .bg-gradient-about {
    width: 908px;
    height: 593px;
    background: radial-gradient(closest-side at 48% 47%, #163c5c 0%, #163b59 52%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box;
  }
}

@media (min-width: 992px) {
  .bg-gradient-about {
    width: 1920px;
    height: 908px;
    background: radial-gradient(closest-side at 29% 40%, #214d71 0%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box;
  }
}

.bg-gradient-remote-control {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 390px;
  height: 390px;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: radial-gradient(closest-side at 48% 47%, #163c5c 0%, #163b59 52%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box;
}

@media (min-width: 576px) {
  .bg-gradient-remote-control {
    width: 808px;
    height: 580px;
    background: radial-gradient(closest-side at 50% 50%, #1f4665 0%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box;
  }
}

@media (min-width: 992px) {
  .bg-gradient-remote-control {
    width: 875px;
    height: 908px;
    background: radial-gradient(closest-side at 56% 48%, #1f4665 0%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box;
  }
}

.bg-gradient-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 390px;
  height: 240px;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: radial-gradient(closest-side at 48% 47%, #163c5c 0%, #163b59 52%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box;
  display: none;
}

@media (min-width: 370px) {
  .bg-gradient-video {
    top: 40%;
    width: 510px;
    height: 430px;
  }
}

@media (min-width: 576px) {
  .bg-gradient-video {
    top: 40%;
    width: 908px;
    height: 593px;
    background: radial-gradient(closest-side at 50% 50%, #1f4665 0%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box;
  }
}

@media (min-width: 771px) {
  .bg-gradient-video {
    display: block;
  }
}

@media (min-width: 992px) {
  .bg-gradient-video {
    top: 50%;
    width: 875px;
    height: 908px;
    background: radial-gradient(closest-side at 48% 47%, #163c5c 0%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box;
  }
}

@media (min-width: 1200px) {
  .bg-gradient-video {
    left: 70%;
  }
}

.bg-gradient-showcase {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 390px;
  height: 240px;
  z-index: -1;
  transform: translate(-50%, -50%);
  display: none;
}

@media (min-width: 771px) {
  .bg-gradient-showcase {
    display: block;
    width: 878px;
    height: 530px;
    background: radial-gradient(closest-side at 48% 47%, #163c5c 0%, #163b59 52%, transparent 100%) 0% 0% no-repeat padding-box;
  }
}

@media (min-width: 992px) {
  .bg-gradient-showcase {
    top: 50%;
    width: 1920px;
    height: 788px;
    background: radial-gradient(closest-side at 48% 47%, #214d71 0%, transparent 100%) 0% 0% no-repeat padding-box;
  }
}

@media (min-width: 1200px) {
  .bg-gradient-showcase {
    width: 1920px;
    height: 908px;
  }
}

.bg-gradient-form {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 390px;
  height: 809px;
  z-index: -1;
  transform: translate(-50%, -50%);
  background: radial-gradient(closest-side at 48% 47%, #163c5c 0%, transparent 100%) 0% 0% no-repeat padding-box;
}

@media (min-width: 576px) {
  .bg-gradient-form {
    width: 768px;
    height: 410px;
    background: radial-gradient(closest-side at 48% 47%, #163c5c 0%, transparent 100%) 0% 0% no-repeat padding-box;
  }
}

@media (min-width: 992px) {
  .bg-gradient-form {
    width: 1920px;
    height: 908px;
    background: radial-gradient(closest-side at 48% 47%, #163c5c 0%, transparent 100%) 0% 0% no-repeat padding-box;
  }
}

/* Margin helpers */
.margin-0 {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .margin-xs-0 {
    margin: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-sm-0 {
    margin: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-md-0 {
    margin: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-lg-0 {
    margin: 0 !important;
  }
}

@media (min-width: 1440px) {
  .margin-xl-0 {
    margin: 0 !important;
  }
}

.margin-bottom-01 {
  margin-bottom: 0.1rem;
}

.margin-bottom-02 {
  margin-bottom: 0.2rem;
}

.margin-bottom-03 {
  margin-bottom: 0.3rem;
}

.margin-bottom-04 {
  margin-bottom: 0.4rem;
}

.margin-bottom-05 {
  margin-bottom: 0.5rem;
}

.margin-bottom-06 {
  margin-bottom: 0.6rem;
}

.margin-bottom-07 {
  margin-bottom: 0.7rem;
}

.margin-bottom-08 {
  margin-bottom: 0.8rem;
}

.margin-bottom-09 {
  margin-bottom: 0.9rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-1-5 {
  margin-bottom: 1.5rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-bottom-2-5 {
  margin-bottom: 2.5rem;
}

.margin-bottom-3 {
  margin-bottom: 3rem;
}

.margin-bottom-3-5 {
  margin-bottom: 3.5rem;
}

.margin-bottom-4 {
  margin-bottom: 4rem;
}

.margin-bottom-5 {
  margin-bottom: 5rem;
}

.margin-bottom-6 {
  margin-bottom: 6rem;
}

.margin-bottom-7 {
  margin-bottom: 7rem;
}

.margin-bottom-8 {
  margin-bottom: 8rem;
}

.margin-bottom-9 {
  margin-bottom: 9rem;
}

.margin-bottom-10 {
  margin-bottom: 10rem;
}

@media (min-width: 576px) {
  .margin-bottom-xs-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-xs-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-xs-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-xs-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-xs-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-xs-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-bottom-sm-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-sm-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-sm-2-5 {
    margin-bottom: 2.5rem;
  }
  .margin-bottom-sm-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-sm-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-sm-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-sm-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-bottom-md-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-md-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-md-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-md-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-md-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-md-6 {
    margin-bottom: 6rem;
  }
  .margin-bottom-md-7 {
    margin-bottom: 7rem;
  }
  .margin-bottom-md-8 {
    margin-bottom: 8rem;
  }
  .margin-bottom-md-10 {
    margin-bottom: 10rem;
  }
  .margin-bottom-md-12 {
    margin-bottom: 12rem;
  }
}

@media (min-width: 1200px) {
  .margin-bottom-lg-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-lg-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-lg-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-lg-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-lg-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-lg-6 {
    margin-bottom: 6rem;
  }
  .margin-bottom-lg-10 {
    margin-bottom: 10rem;
  }
  .margin-bottom-lg-12 {
    margin-bottom: 12rem;
  }
}

@media (min-width: 1440px) {
  .margin-bottom-xl-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-xl-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-xl-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-xl-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-xl-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-xl-6 {
    margin-bottom: 6rem;
  }
  .margin-bottom-xl-8 {
    margin-bottom: 8rem;
  }
  .margin-bottom-xl-10 {
    margin-bottom: 10rem;
  }
}

.margin-top-01 {
  margin-top: 0.1rem;
}

.margin-top-02 {
  margin-top: 0.2rem;
}

.margin-top-03 {
  margin-top: 0.3rem;
}

.margin-top-04 {
  margin-top: 0.4rem;
}

.margin-top-05 {
  margin-top: 0.5rem;
}

.margin-top-06 {
  margin-top: 0.6rem;
}

.margin-top-07 {
  margin-top: 0.7rem;
}

.margin-top-08 {
  margin-top: 0.8rem;
}

.margin-top-09 {
  margin-top: 0.9rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-1-5 {
  margin-top: 1.5rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-2-5 {
  margin-top: 2.5rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-3-5 {
  margin-top: 3.5rem;
}

.margin-top-4 {
  margin-top: 4rem;
}

.margin-top-5 {
  margin-top: 5rem;
}

.margin-top-6 {
  margin-top: 6rem;
}

.margin-top-7 {
  margin-top: 7rem;
}

.margin-top-8 {
  margin-top: 8rem;
}

.margin-top-9 {
  margin-top: 9rem;
}

.margin-top-10 {
  margin-top: 10rem;
}

@media (min-width: 576px) {
  .margin-top-xs-1 {
    margin-top: 1rem;
  }
  .margin-top-xs-2 {
    margin-top: 2rem;
  }
  .margin-top-xs-3 {
    margin-top: 3rem;
  }
  .margin-top-xs-4 {
    margin-top: 4rem;
  }
  .margin-top-xs-5 {
    margin-top: 5rem;
  }
  .margin-top-xs-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-top-sm-1 {
    margin-top: 1rem;
  }
  .margin-top-sm-2 {
    margin-top: 2rem;
  }
  .margin-top-sm-2-5 {
    margin-top: 2.5rem;
  }
  .margin-top-sm-3 {
    margin-top: 3rem;
  }
  .margin-top-sm-4 {
    margin-top: 4rem;
  }
  .margin-top-sm-5 {
    margin-top: 5rem;
  }
  .margin-top-sm-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-top-md-1 {
    margin-top: 1rem;
  }
  .margin-top-md-2 {
    margin-top: 2rem;
  }
  .margin-top-md-3 {
    margin-top: 3rem;
  }
  .margin-top-md-4 {
    margin-top: 4rem;
  }
  .margin-top-md-5 {
    margin-top: 5rem;
  }
  .margin-top-md-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-top-lg-1 {
    margin-top: 1rem;
  }
  .margin-top-lg-2 {
    margin-top: 2rem;
  }
  .margin-top-lg-3 {
    margin-top: 3rem;
  }
  .margin-top-lg-4 {
    margin-top: 4rem;
  }
  .margin-top-lg-5 {
    margin-top: 5rem;
  }
  .margin-top-lg-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-top-xl-1 {
    margin-top: 1rem;
  }
  .margin-top-xl-2 {
    margin-top: 2rem;
  }
  .margin-top-xl-3 {
    margin-top: 3rem;
  }
  .margin-top-xl-4 {
    margin-top: 4rem;
  }
  .margin-top-xl-5 {
    margin-top: 5rem;
  }
  .margin-top-xl-6 {
    margin-top: 6rem;
  }
}

.margin-left-01 {
  margin-left: 0.1rem;
}

.margin-left-02 {
  margin-left: 0.2rem;
}

.margin-left-03 {
  margin-left: 0.3rem;
}

.margin-left-04 {
  margin-left: 0.4rem;
}

.margin-left-05 {
  margin-left: 0.5rem;
}

.margin-left-08 {
  margin-left: 0.8rem;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-left-1-5 {
  margin-left: 1.5rem;
}

.margin-left-2 {
  margin-left: 2rem;
}

.margin-left-3 {
  margin-left: 3rem;
}

.margin-left-4 {
  margin-left: 4rem;
}

.margin-left-5 {
  margin-left: 5rem;
}

.margin-left-6 {
  margin-left: 6rem;
}

.margin-left-7 {
  margin-left: 7rem;
}

.margin-left-8 {
  margin-left: 8rem;
}

.margin-left-9 {
  margin-left: 9rem;
}

.margin-left-10 {
  margin-left: 10rem;
}

@media (min-width: 576px) {
  .margin-left-xs-1 {
    margin-left: 1rem;
  }
  .margin-left-xs-2 {
    margin-left: 2rem;
  }
  .margin-left-xs-3 {
    margin-left: 3rem;
  }
  .margin-left-xs-4 {
    margin-left: 4rem;
  }
  .margin-left-xs-5 {
    margin-left: 5rem;
  }
  .margin-left-xs-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-left-sm-1 {
    margin-left: 1rem;
  }
  .margin-left-sm-2 {
    margin-left: 2rem;
  }
  .margin-left-sm-3 {
    margin-left: 3rem;
  }
  .margin-left-sm-4 {
    margin-left: 4rem;
  }
  .margin-left-sm-5 {
    margin-left: 5rem;
  }
  .margin-left-sm-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-left-md-1 {
    margin-left: 1rem;
  }
  .margin-left-md-2 {
    margin-left: 2rem;
  }
  .margin-left-md-3 {
    margin-left: 3rem;
  }
  .margin-left-md-4 {
    margin-left: 4rem;
  }
  .margin-left-md-5 {
    margin-left: 5rem;
  }
  .margin-left-md-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-left-lg-1 {
    margin-left: 1rem;
  }
  .margin-left-lg-2 {
    margin-left: 2rem;
  }
  .margin-left-lg-3 {
    margin-left: 3rem;
  }
  .margin-left-lg-4 {
    margin-left: 4rem;
  }
  .margin-left-lg-5 {
    margin-left: 5rem;
  }
  .margin-left-lg-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-left-xl-1 {
    margin-left: 1rem;
  }
  .margin-left-xl-2 {
    margin-left: 2rem;
  }
  .margin-left-xl-3 {
    margin-left: 3rem;
  }
  .margin-left-xl-4 {
    margin-left: 4rem;
  }
  .margin-left-xl-5 {
    margin-left: 5rem;
  }
  .margin-left-xl-6 {
    margin-left: 6rem;
  }
}

.margin-right-01 {
  margin-right: 0.1rem;
}

.margin-right-02 {
  margin-right: 0.2rem;
}

.margin-right-03 {
  margin-right: 0.3rem;
}

.margin-right-04 {
  margin-right: 0.4rem;
}

.margin-right-05 {
  margin-right: 0.5rem;
}

.margin-right-08 {
  margin-right: 0.8rem;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-right-1-5 {
  margin-right: 1.5rem;
}

.margin-right-2 {
  margin-right: 2rem;
}

.margin-right-3 {
  margin-right: 3rem;
}

.margin-right-4 {
  margin-right: 4rem;
}

.margin-right-5 {
  margin-right: 5rem;
}

.margin-right-6 {
  margin-right: 6rem;
}

.margin-right-7 {
  margin-right: 7rem;
}

.margin-right-8 {
  margin-right: 8rem;
}

.margin-right-9 {
  margin-right: 9rem;
}

.margin-right-10 {
  margin-right: 10rem;
}

@media (min-width: 576px) {
  .margin-right-xs-1 {
    margin-right: 1rem;
  }
  .margin-right-xs-2 {
    margin-right: 2rem;
  }
  .margin-right-xs-3 {
    margin-right: 3rem;
  }
  .margin-right-xs-4 {
    margin-right: 4rem;
  }
  .margin-right-xs-5 {
    margin-right: 5rem;
  }
  .margin-right-xs-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-right-sm-1 {
    margin-right: 1rem;
  }
  .margin-right-sm-2 {
    margin-right: 2rem;
  }
  .margin-right-sm-3 {
    margin-right: 3rem;
  }
  .margin-right-sm-4 {
    margin-right: 4rem;
  }
  .margin-right-sm-5 {
    margin-right: 5rem;
  }
  .margin-right-sm-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-right-md-1 {
    margin-right: 1rem;
  }
  .margin-right-md-2 {
    margin-right: 2rem;
  }
  .margin-right-md-3 {
    margin-right: 3rem;
  }
  .margin-right-md-4 {
    margin-right: 4rem;
  }
  .margin-right-md-5 {
    margin-right: 5rem;
  }
  .margin-right-md-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-right-lg-1 {
    margin-right: 1rem;
  }
  .margin-right-lg-2 {
    margin-right: 2rem;
  }
  .margin-right-lg-3 {
    margin-right: 3rem;
  }
  .margin-right-lg-4 {
    margin-right: 4rem;
  }
  .margin-right-lg-5 {
    margin-right: 5rem;
  }
  .margin-right-lg-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-right-xl-1 {
    margin-right: 1rem;
  }
  .margin-right-xl-2 {
    margin-right: 2rem;
  }
  .margin-right-xl-3 {
    margin-right: 3rem;
  }
  .margin-right-xl-4 {
    margin-right: 4rem;
  }
  .margin-right-xl-5 {
    margin-right: 5rem;
  }
  .margin-right-xl-6 {
    margin-right: 6rem;
  }
}

.margin-center {
  margin: 0 auto;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

@media (min-width: 576px) {
  .margin-xs-center {
    margin: 0 auto;
  }
  .margin-xs-left-auto {
    margin-left: auto;
  }
  .margin-xs-right-auto {
    margin-right: auto;
  }
}

@media (min-width: 771px) {
  .margin-sm-center {
    margin: 0 auto;
  }
  .margin-sm-left-auto {
    margin-left: auto;
  }
  .margin-sm-right-auto {
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .margin-md-center {
    margin: 0 auto;
  }
  .margin-md-left-auto {
    margin-left: auto;
  }
  .margin-md-right-auto {
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .margin-lg-center {
    margin: 0 auto;
  }
  .margin-lg-left-auto {
    margin-left: auto;
  }
  .margin-lg-right-auto {
    margin-right: auto;
  }
}

.margin-top-0 {
  margin-top: 0 !important;
}

@media (min-width: 576px) {
  .margin-top-xs-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-top-sm-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-top-md-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-top-lg-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-top-lg-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-top-md-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-top-sm-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-top-xs-0 {
    margin-top: 0 !important;
  }
}

.margin-right-0 {
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .margin-right-xs-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-right-sm-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-right-md-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-right-lg-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-right-lg-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-right-md-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-right-sm-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-right-xs-0 {
    margin-right: 0 !important;
  }
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .margin-bottom-xs-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-bottom-sm-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-bottom-md-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-bottom-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-bottom-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-bottom-md-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-bottom-sm-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-bottom-xs-0 {
    margin-bottom: 0 !important;
  }
}

.margin-left-0 {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .margin-left-xs-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-left-sm-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-left-md-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-left-lg-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-left-lg-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-left-md-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-left-sm-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-left-xs-0 {
    margin-left: 0 !important;
  }
}

/* Padding block */
.padding-0 {
  padding: 0 !important;
}

@media (min-width: 576px) {
  .padding-xs-0 {
    padding: 0 !important;
  }
}

@media (min-width: 771px) {
  .padding-sm-0 {
    padding: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-md-0 {
    padding: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-lg-0 {
    padding: 0 !important;
  }
}

@media (min-width: 1440px) {
  .padding-xl-0 {
    padding: 0 !important;
  }
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-top-xs-0 {
  padding-top: 0 !important;
}

@media (min-width: 771px) {
  .padding-top-sm-0 {
    padding-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-top-md-0 {
    padding-top: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-top-lg-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-top-lg-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-top-md-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-top-sm-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-top-xs-0 {
    padding-top: 0 !important;
  }
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-right-xs-0 {
  padding-right: 0 !important;
}

@media (min-width: 771px) {
  .padding-right-sm-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-right-md-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-right-lg-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-right-lg-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-right-md-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-right-sm-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-right-xs-0 {
    padding-right: 0 !important;
  }
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-xs-0 {
  padding-bottom: 0 !important;
}

@media (min-width: 771px) {
  .padding-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-bottom-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-bottom-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-bottom-xs-0 {
    padding-bottom: 0 !important;
  }
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-left-xs-0 {
  padding-left: 0 !important;
}

@media (min-width: 771px) {
  .padding-left-sm-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-left-md-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-left-lg-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-left-lg-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-left-md-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-left-sm-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-left-xs-0 {
    padding-left: 0 !important;
  }
}

/* Position helpers */
.pos-r {
  position: relative;
}

.pos-f {
  position: fixed;
}

.pos-s {
  position: static;
}

.pos-sticky {
  position: sticky;
}

.pos-a {
  position: absolute;
}

.pos-auto {
  position: inherit;
}

/* Size helpers */
.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.h-vh-100 {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}

.h-25 {
  height: 25%;
}

/* Text helpers */
.txt-r {
  text-align: right;
}

.txt-c {
  text-align: center;
}

.txt-l {
  text-align: left;
}

@media (min-width: 576px) {
  .txt-xs-c {
    text-align: center;
  }
  .txt-xs-l {
    text-align: left;
  }
  .txt-xs-r {
    text-align: right;
  }
}

@media (min-width: 771px) {
  .txt-sm-l {
    text-align: left;
  }
  .txt-sm-r {
    text-align: right;
  }
  .txt-sm-c {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .txt-md-l {
    text-align: left;
  }
  .txt-md-r {
    text-align: right;
  }
  .txt-md-c {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .txt-lg-r {
    text-align: right;
  }
  .txt-lg-l {
    text-align: left;
  }
  .txt-lg-c {
    text-align: center;
  }
}

.txt-upper {
  text-transform: uppercase;
}

.txt-lower {
  text-transform: lowercase;
}

.txt-italic {
  font-style: italic;
}

.txt-300 {
  font-weight: 300;
}

.txt-400 {
  font-weight: 400;
}

.txt-600 {
  font-weight: 600;
}

.txt-700 {
  font-weight: 700;
}

.txt-line {
  text-decoration: line-through;
}

.txt-underline {
  text-decoration: underline;
}

/* Z-index helpers */
.z-index--1 {
  z-index: -1;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.z-index-10 {
  z-index: 10;
}

.z-index-100 {
  z-index: 100;
}

.z-index-1000 {
  z-index: 1000;
}

.z-index-10000 {
  z-index: 10000;
}

.z-index-100000 {
  z-index: 100000;
}

/* Opacity helpers */
.opacity-0 {
  opacity: 0;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.opacity-1 {
  opacity: 1;
}

/* Base settings */
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  transition: color 0.15s, background 0.15s, border 0.15s, opacity 0.15s;
}

*:after,
*:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

::-moz-selection {
  color: #fff;
  background-color: #4591cf;
}

::selection {
  color: #fff;
  background-color: #4591cf;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: #656871;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #4591cf;
}

::-webkit-scrollbar-thumb:hover {
  background: #3385c7;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

html {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  font-size: 100%;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 310px;
  margin: 0;
  color: #cfd3de;
  background-color: #121212;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5;
}

em,
i,
.italic {
  font-style: italic;
}

strong,
.strong {
  font-weight: 700;
}

small,
.small {
  font-size: 12px;
  font-size: 0.75rem;
}

h1,
.alfa,
h2,
.beta,
h3,
.gamma,
h4,
.delta,
h5,
.epsilon,
h6,
.zeta {
  margin: 0;
  margin-bottom: 1.5rem;
  color: #cfd3de;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.2;
}

h1 a,
.alfa a,
h2 a,
.beta a,
h3 a,
.gamma a,
h4 a,
.delta a,
h5 a,
.epsilon a,
h6 a,
.zeta a {
  color: inherit;
}

h1,
.alfa {
  font-size: 37px;
  font-size: 2.3125rem;
}

@media (min-width: 576px) {
  h1,
  .alfa {
    font-size: 44px;
    font-size: 2.75rem;
  }
}

@media (min-width: 992px) {
  h1,
  .alfa {
    font-size: 64px;
    font-size: 4rem;
  }
}

h2,
.beta {
  font-size: 24px;
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  h2,
  .beta {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  h2,
  .beta {
    font-size: 32px;
    font-size: 2rem;
  }
}

h3,
.gamma {
  font-size: 16px;
  font-size: 1rem;
}

@media (min-width: 576px) {
  h3,
  .gamma {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (min-width: 992px) {
  h3,
  .gamma {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

h4,
.delta {
  font-size: 28px;
  font-size: 1.75rem;
}

@media (min-width: 576px) {
  h4,
  .delta {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

@media (min-width: 992px) {
  h4,
  .delta {
    font-size: 32px;
    font-size: 2rem;
  }
}

h5,
.epsilon {
  font-size: 24px;
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  h5,
  .epsilon {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

@media (min-width: 992px) {
  h5,
  .epsilon {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

h6,
.zeta {
  font-size: 22px;
  font-size: 1.375rem;
}

@media (min-width: 576px) {
  h6,
  .zeta {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  h6,
  .zeta {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

.h--font-light h1,
.h--font-light .alfa,
.h--font-light h2,
.h--font-light .beta,
.h--font-light h3,
.h--font-light .gamma,
.h--font-light h4,
.h--font-light .delta,
.h--font-light h5,
.h--font-light .epsilon,
.h--font-light h6,
.h--font-light .zeta {
  font-weight: 300;
}

.h--font-regular h1,
.h--font-regular .alfa,
.h--font-regular h2,
.h--font-regular .beta,
.h--font-regular h3,
.h--font-regular .gamma,
.h--font-regular h4,
.h--font-regular .delta,
.h--font-regular h5,
.h--font-regular .epsilon,
.h--font-regular h6,
.h--font-regular .zeta {
  font-weight: 400;
}

.h--font-semibold h1,
.h--font-semibold .alfa,
.h--font-semibold h2,
.h--font-semibold .beta,
.h--font-semibold h3,
.h--font-semibold .gamma,
.h--font-semibold h4,
.h--font-semibold .delta,
.h--font-semibold h5,
.h--font-semibold .epsilon,
.h--font-semibold h6,
.h--font-semibold .zeta {
  font-weight: 600;
}

.h--font-bold h1,
.h--font-bold .alfa,
.h--font-bold h2,
.h--font-bold .beta,
.h--font-bold h3,
.h--font-bold .gamma,
.h--font-bold h4,
.h--font-bold .delta,
.h--font-bold h5,
.h--font-bold .epsilon,
.h--font-bold h6,
.h--font-bold .zeta {
  font-weight: 700;
}

.h--font-black h1,
.h--font-black .alfa,
.h--font-black h2,
.h--font-black .beta,
.h--font-black h3,
.h--font-black .gamma,
.h--font-black h4,
.h--font-black .delta,
.h--font-black h5,
.h--font-black .epsilon,
.h--font-black h6,
.h--font-black .zeta {
  font-weight: 900;
}

.h--font-primary h1,
.h--font-primary .alfa,
.h--font-primary h2,
.h--font-primary .beta,
.h--font-primary h3,
.h--font-primary .gamma,
.h--font-primary h4,
.h--font-primary .delta,
.h--font-primary h5,
.h--font-primary .epsilon,
.h--font-primary h6,
.h--font-primary .zeta {
  font-family: "Kanit", Arial, sans-serif;
}

.h--font-primary h1,
.h--font-primary .alfa,
.h--font-primary h2,
.h--font-primary .beta,
.h--font-primary h3,
.h--font-primary .gamma,
.h--font-primary h4,
.h--font-primary .delta,
.h--font-primary h5,
.h--font-primary .epsilon,
.h--font-primary h6,
.h--font-primary .zeta {
  font-family: "Kanit", Arial, sans-serif;
}

.h--text-upper h1,
.h--text-upper .alfa,
.h--text-upper h2,
.h--text-upper .beta,
.h--text-upper h3,
.h--text-upper .gamma,
.h--text-upper h4,
.h--text-upper .delta,
.h--text-upper h5,
.h--text-upper .epsilon,
.h--text-upper h6,
.h--text-upper .zeta {
  text-transform: uppercase;
}

.h--line-08 h1,
.h--line-08 .alfa,
.h--line-08 h2,
.h--line-08 .beta,
.h--line-08 h3,
.h--line-08 .gamma,
.h--line-08 h4,
.h--line-08 .delta,
.h--line-08 h5,
.h--line-08 .epsilon,
.h--line-08 h6,
.h--line-08 .zeta {
  line-height: 0.8;
}

.h--line-1 h1,
.h--line-1 .alfa,
.h--line-1 h2,
.h--line-1 .beta,
.h--line-1 h3,
.h--line-1 .gamma,
.h--line-1 h4,
.h--line-1 .delta,
.h--line-1 h5,
.h--line-1 .epsilon,
.h--line-1 h6,
.h--line-1 .zeta {
  line-height: 1;
}

.h--line-1-2 h1,
.h--line-1-2 .alfa,
.h--line-1-2 h2,
.h--line-1-2 .beta,
.h--line-1-2 h3,
.h--line-1-2 .gamma,
.h--line-1-2 h4,
.h--line-1-2 .delta,
.h--line-1-2 h5,
.h--line-1-2 .epsilon,
.h--line-1-2 h6,
.h--line-1-2 .zeta {
  line-height: 1.2;
}

.h--line-1-4 h1,
.h--line-1-4 .alfa,
.h--line-1-4 h2,
.h--line-1-4 .beta,
.h--line-1-4 h3,
.h--line-1-4 .gamma,
.h--line-1-4 h4,
.h--line-1-4 .delta,
.h--line-1-4 h5,
.h--line-1-4 .epsilon,
.h--line-1-4 h6,
.h--line-1-4 .zeta {
  line-height: 1.4;
}

.h--line-1-5 h1,
.h--line-1-5 .alfa,
.h--line-1-5 h2,
.h--line-1-5 .beta,
.h--line-1-5 h3,
.h--line-1-5 .gamma,
.h--line-1-5 h4,
.h--line-1-5 .delta,
.h--line-1-5 h5,
.h--line-1-5 .epsilon,
.h--line-1-5 h6,
.h--line-1-5 .zeta {
  line-height: 1.5;
}

.h--line-1-6 h1,
.h--line-1-6 .alfa,
.h--line-1-6 h2,
.h--line-1-6 .beta,
.h--line-1-6 h3,
.h--line-1-6 .gamma,
.h--line-1-6 h4,
.h--line-1-6 .delta,
.h--line-1-6 h5,
.h--line-1-6 .epsilon,
.h--line-1-6 h6,
.h--line-1-6 .zeta {
  line-height: 1.6;
}

.h--line-1-8 h1,
.h--line-1-8 .alfa,
.h--line-1-8 h2,
.h--line-1-8 .beta,
.h--line-1-8 h3,
.h--line-1-8 .gamma,
.h--line-1-8 h4,
.h--line-1-8 .delta,
.h--line-1-8 h5,
.h--line-1-8 .epsilon,
.h--line-1-8 h6,
.h--line-1-8 .zeta {
  line-height: 1.8;
}

.h--line-2 h1,
.h--line-2 .alfa,
.h--line-2 h2,
.h--line-2 .beta,
.h--line-2 h3,
.h--line-2 .gamma,
.h--line-2 h4,
.h--line-2 .delta,
.h--line-2 h5,
.h--line-2 .epsilon,
.h--line-2 h6,
.h--line-2 .zeta {
  line-height: 2;
}

.h--line-2-2 h1,
.h--line-2-2 .alfa,
.h--line-2-2 h2,
.h--line-2-2 .beta,
.h--line-2-2 h3,
.h--line-2-2 .gamma,
.h--line-2-2 h4,
.h--line-2-2 .delta,
.h--line-2-2 h5,
.h--line-2-2 .epsilon,
.h--line-2-2 h6,
.h--line-2-2 .zeta {
  line-height: 2.2;
}

.h--line-2-5 h1,
.h--line-2-5 .alfa,
.h--line-2-5 h2,
.h--line-2-5 .beta,
.h--line-2-5 h3,
.h--line-2-5 .gamma,
.h--line-2-5 h4,
.h--line-2-5 .delta,
.h--line-2-5 h5,
.h--line-2-5 .epsilon,
.h--line-2-5 h6,
.h--line-2-5 .zeta {
  line-height: 2.5;
}

@media (min-width: 576px) {
  .h--line-xs-1 h1,
  .h--line-xs-1 .alfa,
  .h--line-xs-1 h2,
  .h--line-xs-1 .beta,
  .h--line-xs-1 h3,
  .h--line-xs-1 .gamma,
  .h--line-xs-1 h4,
  .h--line-xs-1 .delta,
  .h--line-xs-1 h5,
  .h--line-xs-1 .epsilon,
  .h--line-xs-1 h6,
  .h--line-xs-1 .zeta {
    line-height: 1;
  }
  .h--line-xs-1-2 h1,
  .h--line-xs-1-2 .alfa,
  .h--line-xs-1-2 h2,
  .h--line-xs-1-2 .beta,
  .h--line-xs-1-2 h3,
  .h--line-xs-1-2 .gamma,
  .h--line-xs-1-2 h4,
  .h--line-xs-1-2 .delta,
  .h--line-xs-1-2 h5,
  .h--line-xs-1-2 .epsilon,
  .h--line-xs-1-2 h6,
  .h--line-xs-1-2 .zeta {
    line-height: 1.2;
  }
  .h--line-xs-1-4 h1,
  .h--line-xs-1-4 .alfa,
  .h--line-xs-1-4 h2,
  .h--line-xs-1-4 .beta,
  .h--line-xs-1-4 h3,
  .h--line-xs-1-4 .gamma,
  .h--line-xs-1-4 h4,
  .h--line-xs-1-4 .delta,
  .h--line-xs-1-4 h5,
  .h--line-xs-1-4 .epsilon,
  .h--line-xs-1-4 h6,
  .h--line-xs-1-4 .zeta {
    line-height: 1.4;
  }
  .h--line-xs-1-6 h1,
  .h--line-xs-1-6 .alfa,
  .h--line-xs-1-6 h2,
  .h--line-xs-1-6 .beta,
  .h--line-xs-1-6 h3,
  .h--line-xs-1-6 .gamma,
  .h--line-xs-1-6 h4,
  .h--line-xs-1-6 .delta,
  .h--line-xs-1-6 h5,
  .h--line-xs-1-6 .epsilon,
  .h--line-xs-1-6 h6,
  .h--line-xs-1-6 .zeta {
    line-height: 1.6;
  }
}

@media (min-width: 992px) {
  .h--line-sm-1 h1,
  .h--line-sm-1 .alfa,
  .h--line-sm-1 h2,
  .h--line-sm-1 .beta,
  .h--line-sm-1 h3,
  .h--line-sm-1 .gamma,
  .h--line-sm-1 h4,
  .h--line-sm-1 .delta,
  .h--line-sm-1 h5,
  .h--line-sm-1 .epsilon,
  .h--line-sm-1 h6,
  .h--line-sm-1 .zeta {
    line-height: 1;
  }
  .h--line-sm-1-2 h1,
  .h--line-sm-1-2 .alfa,
  .h--line-sm-1-2 h2,
  .h--line-sm-1-2 .beta,
  .h--line-sm-1-2 h3,
  .h--line-sm-1-2 .gamma,
  .h--line-sm-1-2 h4,
  .h--line-sm-1-2 .delta,
  .h--line-sm-1-2 h5,
  .h--line-sm-1-2 .epsilon,
  .h--line-sm-1-2 h6,
  .h--line-sm-1-2 .zeta {
    line-height: 1.2;
  }
  .h--line-sm-1-4 h1,
  .h--line-sm-1-4 .alfa,
  .h--line-sm-1-4 h2,
  .h--line-sm-1-4 .beta,
  .h--line-sm-1-4 h3,
  .h--line-sm-1-4 .gamma,
  .h--line-sm-1-4 h4,
  .h--line-sm-1-4 .delta,
  .h--line-sm-1-4 h5,
  .h--line-sm-1-4 .epsilon,
  .h--line-sm-1-4 h6,
  .h--line-sm-1-4 .zeta {
    line-height: 1.4;
  }
  .h--line-sm-1-6 h1,
  .h--line-sm-1-6 .alfa,
  .h--line-sm-1-6 h2,
  .h--line-sm-1-6 .beta,
  .h--line-sm-1-6 h3,
  .h--line-sm-1-6 .gamma,
  .h--line-sm-1-6 h4,
  .h--line-sm-1-6 .delta,
  .h--line-sm-1-6 h5,
  .h--line-sm-1-6 .epsilon,
  .h--line-sm-1-6 h6,
  .h--line-sm-1-6 .zeta {
    line-height: 1.6;
  }
}

@media (min-width: 992px) {
  .h--line-md-1 h1,
  .h--line-md-1 .alfa,
  .h--line-md-1 h2,
  .h--line-md-1 .beta,
  .h--line-md-1 h3,
  .h--line-md-1 .gamma,
  .h--line-md-1 h4,
  .h--line-md-1 .delta,
  .h--line-md-1 h5,
  .h--line-md-1 .epsilon,
  .h--line-md-1 h6,
  .h--line-md-1 .zeta {
    line-height: 1;
  }
  .h--line-md-1-2 h1,
  .h--line-md-1-2 .alfa,
  .h--line-md-1-2 h2,
  .h--line-md-1-2 .beta,
  .h--line-md-1-2 h3,
  .h--line-md-1-2 .gamma,
  .h--line-md-1-2 h4,
  .h--line-md-1-2 .delta,
  .h--line-md-1-2 h5,
  .h--line-md-1-2 .epsilon,
  .h--line-md-1-2 h6,
  .h--line-md-1-2 .zeta {
    line-height: 1.2;
  }
  .h--line-md-1-4 h1,
  .h--line-md-1-4 .alfa,
  .h--line-md-1-4 h2,
  .h--line-md-1-4 .beta,
  .h--line-md-1-4 h3,
  .h--line-md-1-4 .gamma,
  .h--line-md-1-4 h4,
  .h--line-md-1-4 .delta,
  .h--line-md-1-4 h5,
  .h--line-md-1-4 .epsilon,
  .h--line-md-1-4 h6,
  .h--line-md-1-4 .zeta {
    line-height: 1.4;
  }
  .h--line-md-1-6 h1,
  .h--line-md-1-6 .alfa,
  .h--line-md-1-6 h2,
  .h--line-md-1-6 .beta,
  .h--line-md-1-6 h3,
  .h--line-md-1-6 .gamma,
  .h--line-md-1-6 h4,
  .h--line-md-1-6 .delta,
  .h--line-md-1-6 h5,
  .h--line-md-1-6 .epsilon,
  .h--line-md-1-6 h6,
  .h--line-md-1-6 .zeta {
    line-height: 1.6;
  }
}

.h--margin-0 .alfa,
.h--margin-0 .beta,
.h--margin-0 .gamma,
.h--margin-0 .delta,
.h--margin-0 .epsilon,
.h--margin-0 .zeta,
.h--margin-0 h1,
.h--margin-0 h2,
.h--margin-0 h3,
.h--margin-0 h4,
.h--margin-0 h5,
.h--margin-0 h6 {
  margin-bottom: 0;
}

.h--margin-01 .alfa,
.h--margin-01 .beta,
.h--margin-01 .gamma,
.h--margin-01 .delta,
.h--margin-01 .epsilon,
.h--margin-01 .zeta,
.h--margin-01 h1,
.h--margin-01 h2,
.h--margin-01 h3,
.h--margin-01 h4,
.h--margin-01 h5,
.h--margin-01 h6 {
  margin-bottom: 0.1rem;
}

.h--margin-02 .alfa,
.h--margin-02 .beta,
.h--margin-02 .gamma,
.h--margin-02 .delta,
.h--margin-02 .epsilon,
.h--margin-02 .zeta,
.h--margin-02 h1,
.h--margin-02 h2,
.h--margin-02 h3,
.h--margin-02 h4,
.h--margin-02 h5,
.h--margin-02 h6 {
  margin-bottom: 0.2rem;
}

.h--margin-03 .alfa,
.h--margin-03 .beta,
.h--margin-03 .gamma,
.h--margin-03 .delta,
.h--margin-03 .epsilon,
.h--margin-03 .zeta,
.h--margin-03 h1,
.h--margin-03 h2,
.h--margin-03 h3,
.h--margin-03 h4,
.h--margin-03 h5,
.h--margin-03 h6 {
  margin-bottom: 0.3rem;
}

.h--margin-04 .alfa,
.h--margin-04 .beta,
.h--margin-04 .gamma,
.h--margin-04 .delta,
.h--margin-04 .epsilon,
.h--margin-04 .zeta,
.h--margin-04 h1,
.h--margin-04 h2,
.h--margin-04 h3,
.h--margin-04 h4,
.h--margin-04 h5,
.h--margin-04 h6 {
  margin-bottom: 0.4rem;
}

.h--margin-05 .alfa,
.h--margin-05 .beta,
.h--margin-05 .gamma,
.h--margin-05 .delta,
.h--margin-05 .epsilon,
.h--margin-05 .zeta,
.h--margin-05 h1,
.h--margin-05 h2,
.h--margin-05 h3,
.h--margin-05 h4,
.h--margin-05 h5,
.h--margin-05 h6 {
  margin-bottom: 0.5rem;
}

.h--margin-06 .alfa,
.h--margin-06 .beta,
.h--margin-06 .gamma,
.h--margin-06 .delta,
.h--margin-06 .epsilon,
.h--margin-06 .zeta,
.h--margin-06 h1,
.h--margin-06 h2,
.h--margin-06 h3,
.h--margin-06 h4,
.h--margin-06 h5,
.h--margin-06 h6 {
  margin-bottom: 0.6rem;
}

.h--margin-07 .alfa,
.h--margin-07 .beta,
.h--margin-07 .gamma,
.h--margin-07 .delta,
.h--margin-07 .epsilon,
.h--margin-07 .zeta,
.h--margin-07 h1,
.h--margin-07 h2,
.h--margin-07 h3,
.h--margin-07 h4,
.h--margin-07 h5,
.h--margin-07 h6 {
  margin-bottom: 0.7rem;
}

.h--margin-08 .alfa,
.h--margin-08 .beta,
.h--margin-08 .gamma,
.h--margin-08 .delta,
.h--margin-08 .epsilon,
.h--margin-08 .zeta,
.h--margin-08 h1,
.h--margin-08 h2,
.h--margin-08 h3,
.h--margin-08 h4,
.h--margin-08 h5,
.h--margin-08 h6 {
  margin-bottom: 0.8rem;
}

.h--margin-09 .alfa,
.h--margin-09 .beta,
.h--margin-09 .gamma,
.h--margin-09 .delta,
.h--margin-09 .epsilon,
.h--margin-09 .zeta,
.h--margin-09 h1,
.h--margin-09 h2,
.h--margin-09 h3,
.h--margin-09 h4,
.h--margin-09 h5,
.h--margin-09 h6 {
  margin-bottom: 0.9rem;
}

.h--margin-1 .alfa,
.h--margin-1 .beta,
.h--margin-1 .gamma,
.h--margin-1 .delta,
.h--margin-1 .epsilon,
.h--margin-1 .zeta,
.h--margin-1 h1,
.h--margin-1 h2,
.h--margin-1 h3,
.h--margin-1 h4,
.h--margin-1 h5,
.h--margin-1 h6 {
  margin-bottom: 1rem;
}

.h--margin-1-2 .alfa,
.h--margin-1-2 .beta,
.h--margin-1-2 .gamma,
.h--margin-1-2 .delta,
.h--margin-1-2 .epsilon,
.h--margin-1-2 .zeta,
.h--margin-1-2 h1,
.h--margin-1-2 h2,
.h--margin-1-2 h3,
.h--margin-1-2 h4,
.h--margin-1-2 h5,
.h--margin-1-2 h6 {
  margin-bottom: 1.2rem;
}

.h--margin-1-5 .alfa,
.h--margin-1-5 .beta,
.h--margin-1-5 .gamma,
.h--margin-1-5 .delta,
.h--margin-1-5 .epsilon,
.h--margin-1-5 .zeta,
.h--margin-1-5 h1,
.h--margin-1-5 h2,
.h--margin-1-5 h3,
.h--margin-1-5 h4,
.h--margin-1-5 h5,
.h--margin-1-5 h6 {
  margin-bottom: 1.5rem;
}

.h--margin-2 .alfa,
.h--margin-2 .beta,
.h--margin-2 .gamma,
.h--margin-2 .delta,
.h--margin-2 .epsilon,
.h--margin-2 .zeta,
.h--margin-2 h1,
.h--margin-2 h2,
.h--margin-2 h3,
.h--margin-2 h4,
.h--margin-2 h5,
.h--margin-2 h6 {
  margin-bottom: 2rem;
}

.h--margin-2-5 .alfa,
.h--margin-2-5 .beta,
.h--margin-2-5 .gamma,
.h--margin-2-5 .delta,
.h--margin-2-5 .epsilon,
.h--margin-2-5 .zeta,
.h--margin-2-5 h1,
.h--margin-2-5 h2,
.h--margin-2-5 h3,
.h--margin-2-5 h4,
.h--margin-2-5 h5,
.h--margin-2-5 h6 {
  margin-bottom: 2.5rem;
}

.h--margin-3 .alfa,
.h--margin-3 .beta,
.h--margin-3 .gamma,
.h--margin-3 .delta,
.h--margin-3 .epsilon,
.h--margin-3 .zeta,
.h--margin-3 h1,
.h--margin-3 h2,
.h--margin-3 h3,
.h--margin-3 h4,
.h--margin-3 h5,
.h--margin-3 h6 {
  margin-bottom: 3rem;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: none;
  font-family: "Kanit", Arial, sans-serif;
}

a:focus,
a:active,
a:visited {
  outline: none;
}

.a-hover:hover, .a-hover:focus {
  text-decoration: underline;
}

.a-link {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 0.6rem 0;
  text-decoration: underline;
}

.a-link__text {
  color: #ffffff;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 300;
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 576px) {
  .a-link__text {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (min-width: 771px) {
  .a-link__text {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.a-link__text--black {
  color: #4591cf;
}

.a-link__text--white {
  color: #ffffff;
}

.a-link__text--purple {
  color: #4591cf;
}

.a-link__text--size-12 {
  font-size: 12px;
  font-size: 0.75rem;
}

.a-link__text--size-14 {
  font-size: 14px;
  font-size: 0.875rem;
}

.a-link__text--size-16 {
  font-size: 16px;
  font-size: 1rem;
}

.a-link__text--size-18 {
  font-size: 18px;
  font-size: 1.125rem;
}

.a-link__icon {
  display: inline-block;
  vertical-align: middle;
}

.a-link--hover-underline {
  position: relative;
}

.a-link--hover-underline:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0;
  height: 1px;
  transition: width 250ms ease-out;
  background-color: currentColor;
}

.a-link--hover-underline:hover:after {
  width: 100%;
}

.a-link--hover-block .a-link--hover-underline {
  position: relative;
}

.a-link--hover-block .a-link--hover-underline:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 0;
  height: 1px;
  transition: width 250ms ease-out;
  background-color: currentColor;
}

.a-link--hover-block:hover .a-link--hover-underline:after {
  width: 100%;
}

p {
  display: block;
  margin-top: 0;
  margin-bottom: 2.5rem;
  color: #cfd3de;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5;
  font-size: 14px;
  font-size: 0.875rem;
  transition: font-size 0.3s cubic-bezier(0.16, 0.08, 0.355, 1);
}

@media (min-width: 576px) {
  p {
    font-size: 16px;
    font-size: 1rem;
  }
}

p a {
  color: currentColor;
}

.p--size-12 p {
  font-size: 12px;
  font-size: 0.75rem;
}

.p--size-14 p {
  font-size: 14px;
  font-size: 0.875rem;
}

.p--size-18 p {
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 576px) {
  .p--size-18 p {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (min-width: 992px) {
  .p--size-18 p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.p--size-20 p {
  font-size: 20px;
  font-size: 1.25rem;
}

.p--size-32 p {
  font-size: 16px;
  font-size: 1rem;
}

@media (min-width: 576px) {
  .p--size-32 p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (min-width: 771px) {
  .p--size-32 p {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

@media (min-width: 992px) {
  .p--size-32 p {
    font-size: 32px;
    font-size: 2rem;
  }
}

.p--line-1 p {
  line-height: 1;
}

.p--line-1-2 p {
  line-height: 1.2;
}

.p--line-1-4 p {
  line-height: 1.4;
}

.p--line-1-5 p {
  line-height: 1.5;
}

.p--line-1-6 p {
  line-height: 1.6;
}

.p--line-1-8 p {
  line-height: 1.8;
}

.p--line-2 p {
  line-height: 2;
}

.p--margin-0 p {
  margin-bottom: 0;
}

.p--margin-01 p {
  margin-bottom: 0.1rem;
}

.p--margin-02 p {
  margin-bottom: 0.2rem;
}

.p--margin-03 p {
  margin-bottom: 0.3rem;
}

.p--margin-04 p {
  margin-bottom: 0.4rem;
}

.p--margin-05 p {
  margin-bottom: 0.5rem;
}

.p--margin-06 p {
  margin-bottom: 0.6rem;
}

.p--margin-07 p {
  margin-bottom: 0.7rem;
}

.p--margin-08 p {
  margin-bottom: 0.8rem;
}

.p--margin-09 p {
  margin-bottom: 0.9rem;
}

.p--margin-1 p {
  margin-bottom: 1rem;
}

.p--margin-1-2 p {
  margin-bottom: 1.2rem;
}

.p--margin-1-5 p {
  margin-bottom: 1.5rem;
}

.p--margin-2 p {
  margin-bottom: 2rem;
}

.p--margin-2-5 p {
  margin-bottom: 2.5rem;
}

.p--semibold p {
  font-weight: 600;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 772px) and (max-width: 992px) {
  .list-style-2 {
    column-gap: 40px;
    column-count: 2;
  }
}

@media (min-width: 1200px) {
  .list-style-2 {
    column-gap: 40px;
    column-count: 2;
  }
}

ul.list-style,
ol.list-style {
  margin-bottom: 0;
}

ul.list-style li,
ol.list-style li {
  position: relative;
  display: block;
  padding-left: 25px;
  color: #cfd3de;
  font-family: "Kanit", Arial, sans-serif;
  font-weight: 300;
  line-height: 2;
  font-size: 13px;
  font-size: 0.8125rem;
}

@media (min-width: 771px) {
  ul.list-style li,
  ol.list-style li {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media (min-width: 992px) {
  ul.list-style li,
  ol.list-style li {
    font-size: 16px;
    font-size: 1rem;
  }
}

ul.list-style a,
ol.list-style a {
  text-decoration: underline;
}

ul.list-style a:focus, ul.list-style a:hover,
ol.list-style a:focus,
ol.list-style a:hover {
  text-decoration: underline;
}

ul.list-style li:before {
  content: '';
  position: absolute;
  top: 9px;
  left: 3px;
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #ff7000;
}

@media (min-width: 771px) {
  ul.list-style li:before {
    top: 10px;
  }
}

@media (min-width: 992px) {
  ul.list-style li:before {
    top: 13px;
  }
}

ol.list-style {
  list-style-position: inside;
  counter-reset: item;
}

ol.list-style li:before {
  content: counter(item) ".";
  counter-increment: item;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  color: #000000;
  line-height: 1.4;
  font-size: 16px;
  font-size: 1rem;
}

/* Content */
section {
  position: relative;
  padding: 3rem 0 3rem;
}

@media (min-width: 771px) {
  section {
    padding: 3rem 0 3rem;
  }
}

.padding-style-1 {
  padding: 1rem 0 1rem;
}

@media (min-width: 771px) {
  .padding-style-1 {
    padding: 1rem 0 1rem;
  }
}

.padding-style-2 {
  padding: 2rem 0 2rem;
}

@media (min-width: 771px) {
  .padding-style-2 {
    padding: 2rem 0 2rem;
  }
}

.padding-style-3 {
  padding: 2rem 0 2rem;
}

@media (min-width: 771px) {
  .padding-style-3 {
    padding: 3rem 0 3rem;
  }
}

.padding-style-3-6 {
  padding: 2rem 0 2rem;
}

@media (min-width: 992px) {
  .padding-style-3-6 {
    padding: 3rem 0 6rem;
  }
}

.padding-style-4 {
  padding: 3rem 0 3rem;
}

@media (min-width: 771px) {
  .padding-style-4 {
    padding: 4rem 0 4rem;
  }
}

.padding-style-5 {
  padding: 3rem 0 3rem;
}

@media (min-width: 771px) {
  .padding-style-5 {
    padding: 5rem 0 5rem;
  }
}

.padding-style-6 {
  padding: 3rem 0 3rem;
}

@media (min-width: 771px) {
  .padding-style-6 {
    padding: 6rem 0 6rem;
  }
}

.padding-style-5-10 {
  padding: 3rem 0 6rem;
}

@media (min-width: 992px) {
  .padding-style-5-10 {
    padding: 5rem 0 10rem;
  }
}

.padding-content-1 {
  padding: 1rem 1rem;
}

.padding-content-2 {
  padding: 2rem 2rem;
}

.padding-content-3 {
  padding: 3rem 3rem;
}

.padding-content-4 {
  padding: 4rem 4rem;
}

.padding-content-5 {
  padding: 5rem 5rem;
}

@media (min-width: 771px) {
  .padding-content-2 {
    padding: 2rem 2rem;
  }
}

.content-width-200 {
  max-width: 200px;
}

.content-width-300 {
  max-width: 300px;
}

.content-width-400 {
  max-width: 400px;
}

.content-width-500 {
  max-width: 500px;
}

.content-width-650 {
  max-width: 650px;
}

.content-width-700 {
  max-width: 700px;
}

.content-width-800 {
  max-width: 800px;
}

.content-width-900 {
  max-width: 900px;
}

main {
  position: relative;
  display: block;
  padding-top: 70px;
  flex: 1 0 auto;
}

@media (min-width: 771px) {
  main {
    padding-top: 0;
  }
}

.container-full {
  margin: 0 auto;
}

.container-small,
.container-normal,
.container-large {
  margin: 0 auto;
  padding: 0 15px;
}

.container-small {
  width: 100%;
}

@media (min-width: 771px) {
  .container-small {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-small {
    width: 900px;
  }
}

@media (min-width: 1200px) {
  .container-small {
    width: 900px;
  }
}

@media (min-width: 1440px) {
  .container-small {
    width: 900px;
  }
}

.container-normal {
  width: 100%;
}

@media (min-width: 771px) {
  .container-normal {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-normal {
    width: 950px;
  }
}

@media (min-width: 1200px) {
  .container-normal {
    width: 1100px;
  }
}

@media (min-width: 1440px) {
  .container-normal {
    width: 1240px;
  }
}

.container-large {
  width: 100%;
}

@media (min-width: 771px) {
  .container-large {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-large {
    width: 950px;
  }
}

@media (min-width: 1200px) {
  .container-large {
    width: 1140px;
  }
}

@media (min-width: 1440px) {
  .container-large {
    width: 1360px;
  }
}

/* Footer */
.footer {
  position: relative;
  z-index: 2;
  background-color: #000000;
  flex-shrink: 0;
  padding: 2rem 0.5rem 1rem;
}

@media (min-width: 992px) {
  .footer {
    padding: 3rem 1rem 3rem;
  }
}

.footer__copy {
  display: block;
  color: #ffffff;
  line-height: 1.6;
  font-size: 14px;
  font-size: 0.875rem;
}

.footer__item {
  display: block;
  margin-bottom: 0.2rem;
}

@media (min-width: 576px) {
  .footer__item {
    margin-bottom: 0.5rem;
  }
}

.footer__item:last-child {
  margin-bottom: 0;
}

.footer__link {
  display: inline-block;
  padding: 0.2rem 0;
  line-height: 1.4;
  color: #cfd3de;
  font-weight: 300;
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 576px) {
  .footer__link {
    padding: 0.3rem 0;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media (min-width: 992px) {
  .footer__link {
    font-size: 16px;
    font-size: 1rem;
  }
}

.footer__link:hover {
  text-decoration: underline;
  color: #4591cf;
}

.logo {
  display: inline-block;
}

.logo__title {
  display: block;
  position: relative;
  color: #ffffff;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}

.logo__title:after {
  content: '';
  display: block;
  width: 63px;
  height: 1px;
  background-color: #4591cf;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.logo__subtitle {
  display: block;
  margin-top: 0.8rem;
}

.logo__subtitle-text--small {
  display: inline-block;
  margin-right: 5px;
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
  font-size: 0.625rem;
}

.logo__subtitle-text {
  color: #ffffff;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
}

.logo__number {
  color: #4591cf;
}

.header__flex {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 20px;
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  width: 100%;
}

.header__link {
  display: inline-block;
  padding: 0.6rem 0;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 576px) {
  .header__link {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media (min-width: 771px) {
  .header__link {
    font-size: 16px;
    font-size: 1rem;
  }
}

.header__link:hover {
  color: #4591cf;
}

.showcase {
  position: relative;
}

@media (min-width: 771px) {
  .showcase {
    margin-bottom: 8.75rem;
  }
}

@media (max-width: 770px) {
  .showcase__image {
    margin-bottom: 0.9375rem;
  }
  .showcase__image img {
    width: 100%;
  }
}

@media (min-width: 771px) {
  .showcase__image--1 {
    padding-left: 60px;
  }
}

@media (min-width: 992px) {
  .showcase__image--1 {
    padding-left: 80px;
  }
}

@media (min-width: 771px) {
  .showcase__image--2 {
    position: absolute;
    bottom: -50px;
    right: 0;
  }
}

@media (min-width: 992px) {
  .showcase__image--2 {
    right: -15px;
    bottom: -50px;
  }
}

@media (min-width: 1200px) {
  .showcase__image--2 {
    bottom: -50px;
  }
}

@media (min-width: 771px) {
  .showcase__image--3 {
    position: absolute;
    bottom: -100px;
    left: 0;
  }
}

@media (min-width: 992px) {
  .showcase__image--3 {
    bottom: -140px;
  }
}

@media (min-width: 1200px) {
  .showcase__image--3 {
    bottom: -140px;
  }
}

.certificate {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.certificate__item {
  display: flex;
  justify-content: center;
  flex: 0 0 33.33%;
  margin-bottom: 1.2rem;
}

@media (max-width: 770px) {
  .certificate__item:nth-child(1) {
    flex: 0 0 25%;
  }
  .certificate__item:nth-child(2) {
    flex: 0 0 50%;
  }
  .certificate__item:nth-child(2) .certificate__content {
    max-width: 125px;
  }
  .certificate__item:nth-child(3) {
    flex: 0 0 25%;
  }
}

@media (min-width: 771px) {
  .certificate__item {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 20%;
  }
}

.certificate__content {
  max-width: 70px;
}

@media (min-width: 771px) {
  .certificate__content {
    max-width: none;
  }
}

.video {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.video:hover .video__icon svg {
  fill: #4591cf;
}

.video__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  z-index: 4;
}

.video__icon svg {
  font-size: 80px;
  font-size: 5rem;
  fill: #ffffff;
}

.youtube-block {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
}

@media (min-width: 771px) {
  .youtube-block {
    height: 219px;
  }
}

@media (min-width: 992px) {
  .youtube-block {
    height: 304px;
  }
}

@media (min-width: 1200px) {
  .youtube-block {
    height: 354px;
  }
}

.youtube-block iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.benefit__item {
  margin-bottom: 1rem;
}

.benefit__content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 1rem 1rem;
  min-height: 200px;
  border: 1px solid #3e414d;
  border-radius: 5px;
  overflow: hidden;
  transition: all 250ms ease-out;
  cursor: pointer;
}

.benefit__content:focus, .benefit__content:hover {
  box-shadow: 20px 20px 12px rgba(0, 0, 0, 0.07);
  background-color: #4591cf;
  border-color: #4591cf;
}

@media (min-width: 992px) {
  .benefit__content:focus, .benefit__content:hover {
    transform: translateY(-30px);
  }
}

.benefit__content svg {
  font-size: 60px;
  font-size: 3.75rem;
}

.benefit__text {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.4;
  color: #cfd3de;
}

.hero-section {
  padding: 2rem 0 1rem;
}

@media (min-width: 771px) {
  .hero-section {
    padding: 3rem 0 3rem;
  }
}

@media (min-width: 1200px) {
  .hero-section {
    padding: 1rem 0 3rem;
  }
}

@media (max-width: 770px) {
  .hero {
    max-width: 280px;
    margin: 0 auto;
  }
}

.hero-image {
  margin: 0 -25px 1.5rem;
}

@media (min-width: 771px) {
  .hero-image {
    margin: 0;
  }
}

.hero-price {
  display: inline-block;
  color: #ff7000;
  font-weight: 700;
  font-size: 32px;
  font-size: 2rem;
  margin-right: 1rem;
}

@media (min-width: 771px) {
  .hero-price {
    font-size: 36px;
    font-size: 2.25rem;
  }
}

@media (min-width: 992px) {
  .hero-price {
    font-size: 42px;
    font-size: 2.625rem;
  }
}

.hero-text {
  color: #cfd3de;
  font-weight: 400;
  line-height: 1.6;
  font-size: 12px;
  font-size: 0.75rem;
}

@media (min-width: 576px) {
  .hero-text {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media (min-width: 771px) {
  .hero-text {
    font-size: 16px;
    font-size: 1rem;
  }
}
