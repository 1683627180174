.showcase {
  position: relative;
  @include bp(small) {
    margin-bottom: calculate-rem(140px);
  }
}

.showcase__image {
  @include bp(rev-small) {
    margin-bottom: calculate-rem(15px);
    img {
      width: 100%;
    }
  }
}

.showcase__image--1 {
  @include bp(small) {
    padding-left: 60px;
  }
  @include bp(medium) {
    padding-left: 80px;
  }
}

.showcase__image--2 {
  @include bp(small) {
    position: absolute;
    bottom: -50px;
    right: 0;
    // width: 325px;
    // height: auto;
  }
  @include bp(medium) {
    right: -15px;
    bottom: -50px;
    // width: 500px;
    // height: auto;
  }
  @include bp(large) {
    bottom: -50px;
    // width: 653px;
    // height: auto;
  }
}

.showcase__image--3 {
  @include bp(small) {
    position: absolute;
    bottom: -100px;
    left: 0;
    // width: 240px;
    // height: auto;
  }
  @include bp(medium) {
    bottom: -140px;
    // width: 360px;
    // height: auto;
  }
  @include bp(large) {
    bottom: -140px;
    // width: 475px;
    // height: auto;
  }
}
