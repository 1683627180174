.video {
  display: inline-block;
  position: relative;
  cursor: pointer;
  &:hover {
    .video__icon svg {
      fill: $color-blue;
    }
  }
}

.video__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  z-index: 4;
  svg {
    @include font-size(80px);
    fill: $color-white;
  }
}

.youtube-block {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  @include bp(small) {
    height: 219px;
  }
  @include bp(medium) {
    height: 304px;
  }
  @include bp(large) {
    height: 354px;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
