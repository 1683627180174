/* Gutter helpers */

.no-gutter{
	margin: 0;
}

.no-gutter > [class*="col-"]{
	padding-right: 0;
	padding-left: 0;
}

.gutter-4.row{
	margin-right: -2px;
	margin-left: -2px;
}
.gutter-4 > [class*="col-"],
.gutter-4 > [class*=" col-"]{
	padding-right: 2px;
	padding-left: 2px;
}

.gutter-6.row{
	margin-right: -3px;
	margin-left: -3px;
}
.gutter-6 > [class*="col-"],
.gutter-6 > [class*=" col-"]{
	padding-right: 3px;
	padding-left: 3px;
}

.gutter-10.row{
	margin-right: -5px;
	margin-left: -5px;
}
.gutter-10 > [class*="col-"],
.gutter-10 > [class*=" col-"]{
	padding-right: 5px;
	padding-left: 5px;
}

.gutter-20.row{
	margin-right: -10px;
	margin-left: -10px;
}
.gutter-20 > [class*="col-"],
.gutter-20 > [class*=" col-"]{
	padding-right: 10px;
	padding-left: 10px;
}

.gutter-30.row{
	margin-right: -15px;
	margin-left: -15px;
}
.gutter-30 > [class*="col-"],
.gutter-30 > [class*=" col-"]{
	padding-right: 15px;
	padding-left: 15px;
}

.gutter-40.row{
	margin-right: -20px;
	margin-left: -20px;
}
.gutter-40 > [class*="col-"],
.gutter-40 > [class*=" col-"]{
	padding-right: 20px;
	padding-left: 20px;
}

.gutter-50.row{
	margin-right: -25px;
	margin-left: -25px;
}
.gutter-50 > [class*="col-"],
.gutter-50 > [class*=" col-"]{
	padding-right: 25px;
	padding-left: 25px;
}

.gutter-60.row{
	margin-right: -30px;
	margin-left: -30px;
}
.gutter-60 > [class*="col-"],
.gutter-60 > [class*=" col-"]{
	padding-right: 30px;
	padding-left: 30px;
}

.gutter-sm-80.row{
	margin-right: -40px;
	margin-left: -40px;
}
.gutter-sm-80 > [class*="col-"],
.gutter-sm-80 > [class*=" col-"]{
	padding-right: 40px;
	padding-left: 40px;
}

.gutter-sm-100.row{
	margin-right: -50px;
	margin-left: -50px;
}
.gutter-sm-100 > [class*="col-"],
.gutter-sm-100 > [class*=" col-"]{
	padding-right: 50px;
	padding-left: 50px;
}


@include bp(xsmall){
	.gutter-xs-10.row{
		margin-right: -5px;
		margin-left: -5px;
	}
	.gutter-xs-10 > [class*="col-"],
	.gutter-xs-10 > [class*=" col-"]{
		padding-right: 5px;
		padding-left: 5px;
	}


	.gutter-xs-20.row{
		margin-right: -10px;
		margin-left: -10px;
	}
	.gutter-xs-20 > [class*="col-"],
	.gutter-xs-20 > [class*=" col-"]{
		padding-right: 10px;
		padding-left: 10px;
	}


	.gutter-xs-30.row{
		margin-right: -15px;
		margin-left: -15px;
	}
	.gutter-xs-30 > [class*="col-"],
	.gutter-xs-30 > [class*=" col-"]{
		padding-right: 15px;
		padding-left: 15px;
	}


	.gutter-xs-40.row{
		margin-right: -20px;
		margin-left: -20px;
	}
	.gutter-xs-40 > [class*="col-"],
	.gutter-xs-40 > [class*=" col-"]{
		padding-right: 20px;
		padding-left: 20px;
	}

	.gutter-xs-50.row{
		margin-right: -25px;
		margin-left: -25px;
	}
	.gutter-xs-50 > [class*="col-"],
	.gutter-xs-50 > [class*=" col-"]{
		padding-right: 25px;
		padding-left: 25px;
	}

	.gutter-xs-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-xs-60 > [class*="col-"],
	.gutter-xs-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}

	.gutter-xs-100.row{
		margin-right: -50px;
		margin-left: -50px;
	}
	.gutter-xs-100 > [class*="col-"],
	.gutter-xs-100 > [class*=" col-"]{
		padding-right: 50px;
		padding-left: 50px;
	}
}

@include bp(small){
	.gutter-sm-10.row{
		margin-right: -5px;
		margin-left: -5px;
	}
	.gutter-sm-10 > [class*="col-"],
	.gutter-sm-10 > [class*=" col-"]{
		padding-right: 5px;
		padding-left: 5px;
	}


	.gutter-sm-20.row{
		margin-right: -10px;
		margin-left: -10px;
	}
	.gutter-sm-20 > [class*="col-"],
	.gutter-sm-20 > [class*=" col-"]{
		padding-right: 10px;
		padding-left: 10px;
	}


	.gutter-sm-30.row{
		margin-right: -15px;
		margin-left: -15px;
	}
	.gutter-sm-30 > [class*="col-"],
	.gutter-sm-30 > [class*=" col-"]{
		padding-right: 15px;
		padding-left: 15px;
	}


	.gutter-sm-40.row{
		margin-right: -20px;
		margin-left: -20px;
	}
	.gutter-sm-40 > [class*="col-"],
	.gutter-sm-40 > [class*=" col-"]{
		padding-right: 20px;
		padding-left: 20px;
	}

	.gutter-sm-50.row{
		margin-right: -25px;
		margin-left: -25px;
	}
	.gutter-sm-50 > [class*="col-"],
	.gutter-sm-50 > [class*=" col-"]{
		padding-right: 25px;
		padding-left: 25px;
	}

	.gutter-sm-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-sm-60 > [class*="col-"],
	.gutter-sm-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}

	.gutter-sm-100.row{
		margin-right: -50px;
		margin-left: -50px;
	}
	.gutter-sm-100 > [class*="col-"],
	.gutter-sm-100 > [class*=" col-"]{
		padding-right: 50px;
		padding-left: 50px;
	}
}


@include bp(medium){
	.no-md-gutter{
		margin: 0;
	}

	.no-md-gutter > [class*="col-"]{
		padding-right: 0;
		padding-left: 0;
	}

	.gutter-md-10.row{
		margin-right: -5px;
		margin-left: -5px;
	}
	.gutter-md-10 > [class*="col-"],
	.gutter-md-10 > [class*=" col-"]{
		padding-right: 5px;
		padding-left: 5px;
	}


	.gutter-md-20.row{
		margin-right: -10px;
		margin-left: -10px;
	}
	.gutter-md-20 > [class*="col-"],
	.gutter-md-20 > [class*=" col-"]{
		padding-right: 10px;
		padding-left: 10px;
	}


	.gutter-md-30.row{
		margin-right: -15px;
		margin-left: -15px;
	}
	.gutter-md-30 > [class*="col-"],
	.gutter-md-30 > [class*=" col-"]{
		padding-right: 15px;
		padding-left: 15px;
	}


	.gutter-md-40.row{
		margin-right: -20px;
		margin-left: -20px;
	}
	.gutter-md-40 > [class*="col-"],
	.gutter-md-40 > [class*=" col-"]{
		padding-right: 20px;
		padding-left: 20px;
	}

	.gutter-md-50.row{
		margin-right: -25px;
		margin-left: -25px;
	}
	.gutter-md-50 > [class*="col-"],
	.gutter-md-50 > [class*=" col-"]{
		padding-right: 25px;
		padding-left: 25px;
	}

	.gutter-md-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-md-60 > [class*="col-"],
	.gutter-md-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}

	.gutter-md-100.row{
		margin-right: -50px;
		margin-left: -50px;
	}
	.gutter-md-100 > [class*="col-"],
	.gutter-md-100 > [class*=" col-"]{
		padding-right: 50px;
		padding-left: 50px;
	}
}

@include bp(large){
	.gutter-lg-10.row{
		margin-right: -5px;
		margin-left: -5px;
	}
	.gutter-lg-10 > [class*="col-"],
	.gutter-lg-10 > [class*=" col-"]{
		padding-right: 5px;
		padding-left: 5px;
	}


	.gutter-lg-20.row{
		margin-right: -10px;
		margin-left: -10px;
	}
	.gutter-lg-20 > [class*="col-"],
	.gutter-lg-20 > [class*=" col-"]{
		padding-right: 10px;
		padding-left: 10px;
	}


	.gutter-lg-30.row{
		margin-right: -15px;
		margin-left: -15px;
	}
	.gutter-lg-30 > [class*="col-"],
	.gutter-lg-30 > [class*=" col-"]{
		padding-right: 15px;
		padding-left: 15px;
	}


	.gutter-lg-40.row{
		margin-right: -20px;
		margin-left: -20px;
	}
	.gutter-lg-40 > [class*="col-"],
	.gutter-lg-40 > [class*=" col-"]{
		padding-right: 20px;
		padding-left: 20px;
	}

	.gutter-lg-50.row{
		margin-right: -25px;
		margin-left: -25px;
	}
	.gutter-lg-50 > [class*="col-"],
	.gutter-lg-50 > [class*=" col-"]{
		padding-right: 25px;
		padding-left: 25px;
	}

	.gutter-lg-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-lg-60 > [class*="col-"],
	.gutter-lg-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}

	.gutter-lg-80.row{
		margin-right: -40px;
		margin-left: -40px;
	}
	.gutter-lg-80 > [class*="col-"],
	.gutter-lg-80 > [class*=" col-"]{
		padding-right: 40px;
		padding-left: 40px;
	}

	.gutter-lg-100.row{
		margin-right: -50px;
		margin-left: -50px;
	}
	.gutter-lg-100 > [class*="col-"],
	.gutter-lg-100 > [class*=" col-"]{
		padding-right: 50px;
		padding-left: 50px;
	}
}


@include bp(xlarge){
	.gutter-xl-30.row{
		margin-right: -15px;
		margin-left: -15px;
	}
	.gutter-xl-30 > [class*="col-"],
	.gutter-xl-30 > [class*=" col-"]{
		padding-right: 15px;
		padding-left: 15px;
	}

	.gutter-xl-40.row{
		margin-right: -20px;
		margin-left: -20px;
	}
	.gutter-xl-40 > [class*="col-"],
	.gutter-xl-40 > [class*=" col-"]{
		padding-right: 20px;
		padding-left: 20px;
	}

	.gutter-xl-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-xl-60 > [class*="col-"],
	.gutter-xl-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}

	.gutter-xl-80.row{
		margin-right: -40px;
		margin-left: -40px;
	}
	.gutter-xl-80 > [class*="col-"],
	.gutter-xl-80 > [class*=" col-"]{
		padding-right: 40px;
		padding-left: 40px;
	}

	.gutter-xl-120.row{
		margin-right: -60px;
		margin-left: -60px;
	}
	.gutter-xl-120 > [class*="col-"],
	.gutter-xl-120 > [class*=" col-"]{
		padding-right: 60px;
		padding-left: 60px;
	}
}

@include bp(xxlarge){
	
	.gutter-xxl-30.row{
		margin-right: -15px;
		margin-left: -15px;
	}
	.gutter-xxl-30 > [class*="col-"],
	.gutter-xxl-30 > [class*=" col-"]{
		padding-right: 15px;
		padding-left: 15px;
	}

	.gutter-xxl-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-xxl-60 > [class*="col-"],
	.gutter-xxl-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}

	.gutter-xxl-120.row{
		margin-right: -60px;
		margin-left: -60px;
	}
	.gutter-xxl-120 > [class*="col-"],
	.gutter-xxl-120 > [class*=" col-"]{
		padding-right: 60px;
		padding-left: 60px;
	}

	.gutter-xxl-180.row{
		margin-right: -90px;
		margin-left: -90px;
	}
	.gutter-xxl-180 > [class*="col-"],
	.gutter-xxl-180 > [class*=" col-"]{
		padding-right: 90px;
		padding-left: 90px;
	}
}

@include bp(xxxlarge){
	.gutter-xxxl-30.row{
		margin-right: -15px;
		margin-left: -15px;
	}
	.gutter-xxxl-30 > [class*="col-"],
	.gutter-xxxl-30 > [class*=" col-"]{
		padding-right: 15px;
		padding-left: 15px;
	}

	.gutter-xxxl-60.row{
		margin-right: -30px;
		margin-left: -30px;
	}
	.gutter-xxxl-60 > [class*="col-"],
	.gutter-xxxl-60 > [class*=" col-"]{
		padding-right: 30px;
		padding-left: 30px;
	}
}