/* Form base */

%input {
  width: 100%;
  height: 45px;
  padding: 0.2rem 1rem;
  border: 1px solid $color-gray-light;
  background-color: transparent;
  color: $color-gray-light;

  font-family: $font-primary;
  font-weight: 300;
  @include font-size(16px);
  @include border-radius(5px);
  outline: none !important;
  -webkit-appearance: none;
  @include bp(medium) {
    padding: 0.2rem 2.5rem;
    height: 80px;
  }
  &:not(:placeholder-shown) {
    border-color: $color-white;
  }
  &:focus {
    border-color: $color-white;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 1px solid $color-gray-light;
    -webkit-text-fill-color: $color-gray-light;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.form__input {
  @extend %input;
}

.form__input-ext {
  border: 0;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}

.form__textarea {
  line-height: 1.5rem;
  resize: none;

  @extend %input;
  height: 270px;
  padding: 1.5rem 1rem;
  @include bp(medium) {
    padding: 2rem 2.5rem;
    height: 370px;
  }
}

.form__select {
  width: 100%;
  height: 60px;
  padding: 0.2rem 1.5rem;
  font-family: $font-primary;
  font-weight: 700;
  line-height: 1.2;
  color: $color-black;
  border: 2px solid $color-black;
  outline: none;
  background-color: $color-white;

  @include font-size(16px);
  -webkit-appearance: value;
  -moz-appearance: value;
  -ms-appearance: value;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.form__icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 60px;
  height: 60px;
  @include font-size(22px);
  color: $color-black;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.form__icon-block {
  .form__input {
    padding-right: 60px;
  }
}

.form__label {
  display: block;
  margin: 0 0 10px;
  font-family: $font-primary;
  font-weight: 300;
  color: $color-white-light;

  @include font-size(16px);
  @include bp(xsmall) {
    @include font-size(18px);
  }
  @include bp(medium) {
    @include font-size(20px);
  }
}

.form__required {
  @include font-size(12px);
  @include bp(xsmall) {
    @include font-size(14px);
  }
  @include bp(medium) {
    @include font-size(14px);
  }
}

.form__field {
  position: relative;
}

.form__field--small {
  margin-bottom: 0.5rem;
}

.form__field--medium {
  margin-bottom: 1.2rem;
  @include bp(medium) {
    margin-bottom: 2rem;
  }
}

.form__field--big {
  margin-bottom: 2rem;
}

// .form__placeholder {
//   position: relative;
//   z-index: 1;
//   &.is-active {
//     .form__label {
//       width: calc(100% - 20px);
//       transform: translateX(1px) translateY(1px);
//       @include font-size(12px);
//       padding: 10px 25px 5px;
//     }
//   }
//   .form__input,
//   .form__textarea {
//     padding: 25px 25px 13px;
//   }
//   .select2-container .select2-selection--single {
//     padding: 7px 40px 0 25px;
//   }
//   .form__label {
//     position: absolute;
//     z-index: 10;
//     top: 0;
//     left: 0;
//     margin: 0;
//     // padding: 8px 0 2px;
//     cursor: text;
//     transition: all 0.3s ease;
//     transform: translateX(1px) translateY(8px);
//     text-transform: none;
//     pointer-events: none;
//     padding: 15px 25px 10px;
//   }
// }

// .form__placeholder-textarea {
//   .form__label {
//     top: 1px;
//     left: 2px;
//     background: white;
//   }
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid transparent;
  // -webkit-text-fill-color: transparent;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
