/* Content */

section {
  position: relative;
  padding: 3rem 0 3rem;

  @include bp(small) {
    padding: 3rem 0 3rem;
  }
}

.padding-style-1 {
  padding: 1rem 0 1rem;

  @include bp(small) {
    padding: 1rem 0 1rem;
  }
}

.padding-style-2 {
  padding: 2rem 0 2rem;

  @include bp(small) {
    padding: 2rem 0 2rem;
  }
}

.padding-style-3 {
  padding: 2rem 0 2rem;

  @include bp(small) {
    padding: 3rem 0 3rem;
  }
}

.padding-style-3-6 {
  padding: 2rem 0 2rem;

  @include bp(medium) {
    padding: 3rem 0 6rem;
  }
}

.padding-style-4 {
  padding: 3rem 0 3rem;

  @include bp(small) {
    padding: 4rem 0 4rem;
  }
}

.padding-style-5 {
  padding: 3rem 0 3rem;

  @include bp(small) {
    padding: 5rem 0 5rem;
  }
}

.padding-style-6 {
  padding: 3rem 0 3rem;

  @include bp(small) {
    padding: 6rem 0 6rem;
  }
}

.padding-style-5-10 {
  padding: 3rem 0 6rem;

  @include bp(medium) {
    padding: 5rem 0 10rem;
  }
}

.padding-content-1 {
  padding: 1rem 1rem;
}

.padding-content-2 {
  padding: 2rem 2rem;
}

.padding-content-3 {
  padding: 3rem 3rem;
}

.padding-content-4 {
  padding: 4rem 4rem;
}

.padding-content-5 {
  padding: 5rem 5rem;
}

@include bp(small) {
  .padding-content-2 {
    padding: 2rem 2rem;
  }
}

.content-width-200 {
  max-width: 200px;
}

.content-width-300 {
  max-width: 300px;
}

.content-width-400 {
  max-width: 400px;
}

.content-width-500 {
  max-width: 500px;
}

.content-width-650 {
  max-width: 650px;
}

.content-width-700 {
  max-width: 700px;
}

.content-width-800 {
  max-width: 800px;
}

.content-width-900 {
  max-width: 900px;
}

main {
  position: relative;
  display: block;
  padding-top: 70px;
  flex: 1 0 auto;
  @include bp(small) {
    padding-top: 0;
  }
}

.container-full {
  margin: 0 auto;
}

.container-small,
.container-normal,
.container-large {
  margin: 0 auto;
  padding: 0 15px;
}

.container-small {
  width: 100%;
  @include bp(small) {
    width: 730px;
  }
  @include bp(medium) {
    width: 900px;
  }
  @include bp(large) {
    width: 900px;
  }
  @include bp(xlarge) {
    width: 900px;
  }
}

.container-normal {
  width: 100%;
  @include bp(small) {
    width: 730px;
  }
  @include bp(medium) {
    width: 950px;
  }
  @include bp(large) {
    width: 1100px;
  }
  @include bp(xlarge) {
    width: 1240px;
  }
}

.container-large {
  width: 100%;
  @include bp(small) {
    width: 730px;
  }
  @include bp(medium) {
    width: 950px;
  }
  @include bp(large) {
    width: 1140px;
  }
  @include bp(xlarge) {
    width: 1360px;
  }
}
