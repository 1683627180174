/* Buttons helper */

.btn {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0;
  padding: 0.8em 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  outline: none !important;
  background: $color-black;
  text-align: center;

  @include transition(background-color, 250ms, ease);
  @include border-radius(5px);
  @include font-size(16px);
  -webkit-appearance: none;
  @include bp(xsmall) {
    @include font-size(18px);
  }
  @include bp(small) {
    @include font-size(20px);
  }
  &,
  &:visited,
  &:active,
  &:focus {
    color: white;
  }

  &:hover {
    @include transition(background-color, 250ms, ease);
    @include bp(medium) {
      cursor: pointer;
      text-decoration: none;
      color: white;
    }
  }
}

.btn--responsive {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
}

// Buttons FONT SIZE
.btn--font-size-xsmall {
  @include font-size(12px);
}

.btn--font-size-small {
  @include font-size(14px);
}

.btn--font-size-medium {
  @include font-size(16px);
}

.btn--font-size-big {
  @include font-size(18px);
}

.btn--font-size-large {
  @include font-size(20px);
}

// Buttons SIZE
.btn--size-small {
  padding: 0.6em 1.2rem;
}

.btn--size-medium {
  padding: 1.2em 1.5rem;
}

.btn--size-big {
  padding: 2.2em 2rem;
}

// Buttons MIN WIDTH
.btn--min-width-xsmall {
  min-width: 120px;
}

.btn--min-width-small {
  min-width: 160px;
}

.btn--min-width-medium {
  min-width: 280px;
}

.btn--min-width-big {
  min-width: 300px;
}

// Buttons TYPES
.btn--black-fill {
  color: $color-white;
  border: 2px solid transparent;
  background-color: $color-black;
  &,
  &:visited,
  &:active,
  &:focus {
    color: $color-white;
  }
  &:hover {
    color: $color-black;
    background-color: $color-white;
    .btn__icon {
      color: $color-black;
    }
  }
  .btn__icon {
    color: $color-white;
  }
}

.btn--blue-fill {
  color: $color-white;
  border: 2px solid transparent;
  background-color: $color-blue;
  &,
  &:visited,
  &:active,
  &:focus {
    color: $color-white;
  }
  &:hover {
    color: $color-white;
    background-color: darken($color-blue, 10%);
    .btn__icon {
      color: $color-white;
    }
  }
  .btn__icon {
    color: $color-white;
  }
}

.btn--white-outline {
  color: $color-white;
  border: 1px solid $color-blue;
  background-color: transparent;
  &,
  &:visited,
  &:active,
  &:focus {
    color: $color-white;
  }
  &:hover {
    color: $color-white;
    border: 1px solid $color-blue;
    background-color: $color-blue;
  }
}
