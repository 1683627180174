.hero-section {
  padding: 2rem 0 1rem;
  @include bp(small) {
    padding: 3rem 0 3rem;
  }
  @include bp(large) {
    padding: 1rem 0 3rem;
  }
}

.hero {
  @include bp(rev-small) {
    max-width: 280px;
    margin: 0 auto;
  }
}

.hero-image {
  margin: 0 -25px 1.5rem;
  @include bp(small) {
    margin: 0;
  }
}

.hero-price {
  display: inline-block;
  color: $color-orange;
  font-weight: 700;
  @include font-size(32px);
  margin-right: 1rem;
  @include bp(small) {
    @include font-size(36px);
  }
  @include bp(medium) {
    @include font-size(42px);
  }
}

.hero-text {
  color: $color-white-light;
  font-weight: 400;
  line-height: 1.6;
  @include font-size(12px);
  @include bp(xsmall) {
    @include font-size(14px);
  }
  @include bp(small) {
    @include font-size(16px);
  }
}
