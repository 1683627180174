ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-style-2 {
  @include bp(excl-medium) {
    column-gap: 40px;
    column-count: 2;
  }
  @include bp(large) {
    column-gap: 40px;
    column-count: 2;
  }
}

ul.list-style,
ol.list-style {
  margin-bottom: 0;

  li {
    position: relative;
    display: block;
    padding-left: 25px;
    color: $color-white-light;
    font-family: $font-primary;
    font-weight: 300;
    line-height: 2;
    @include font-size(13px);
    @include bp(small) {
      @include font-size(14px);
    }
    @include bp(medium) {
      @include font-size(16px);
    }
  }
  a {
    // color: $color-blue;
    text-decoration: underline;
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}

ul.list-style {
  li {
    &:before {
      content: '';
      position: absolute;
      top: 9px;
      left: 3px;
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: $color-orange;
      @include bp(small) {
        top: 10px;
      }
      @include bp(medium) {
        top: 13px;
      }
    }
  }
}

ol.list-style {
  list-style-position: inside;
  counter-reset: item;
  li {
    &:before {
      content: counter(item) '.';
      counter-increment: item;
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      color: $color-black;
      line-height: 1.4;
      @include font-size(16px);
    }
  }
}
