/* Text helpers */

.txt-r {
  text-align: right;
}

.txt-c {
  text-align: center;
}

.txt-l {
  text-align: left;
}

@include bp(xsmall) {
  .txt-xs-c {
    text-align: center;
  }
  .txt-xs-l {
    text-align: left;
  }
  .txt-xs-r {
    text-align: right;
  }
}

@include bp(small) {
  .txt-sm-l {
    text-align: left;
  }
  .txt-sm-r {
    text-align: right;
  }
  .txt-sm-c {
    text-align: center;
  }
}

@include bp(medium) {
  .txt-md-l {
    text-align: left;
  }
  .txt-md-r {
    text-align: right;
  }
  .txt-md-c {
    text-align: center;
  }
}

@include bp(large) {
  .txt-lg-r {
    text-align: right;
  }
  .txt-lg-l {
    text-align: left;
  }
  .txt-lg-c {
    text-align: center;
  }
}

.txt-upper {
  text-transform: uppercase;
}

.txt-lower {
  text-transform: lowercase;
}

.txt-italic {
  font-style: italic;
}

.txt-300 {
  font-weight: 300;
}

.txt-400 {
  font-weight: 400;
}

.txt-600 {
  font-weight: 600;
}

.txt-700 {
  font-weight: 700;
}

.txt-line {
  text-decoration: line-through;
}

.txt-underline {
  text-decoration: underline;
}
