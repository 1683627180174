.certificate {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // @include bp(small) {
  //   background-color: $color-black;
  // }
}

.certificate__item {
  display: flex;
  justify-content: center;
  flex: 0 0 33.33%;
  margin-bottom: 1.2rem;
  @include bp(rev-small) {
    &:nth-child(1) {
      flex: 0 0 25%;
    }
    &:nth-child(2) {
      flex: 0 0 50%;
      .certificate__content {
        max-width: 125px;
      }
    }
    &:nth-child(3) {
      flex: 0 0 25%;
    }
  }
  @include bp(small) {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 20%;
    // &:first-child {
    //   justify-content: flex-start;
    // }
    // &:last-child {
    //   justify-content: flex-end;
    // }
  }
}

.certificate__content {
  max-width: 70px;
  @include bp(small) {
    max-width: none;
  }
}

.certificate__content img {
}
