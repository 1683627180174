/* Footer */

.footer {
  position: relative;
  z-index: 2;

  background-color: $color-black;

  flex-shrink: 0;
  padding: 2rem 0.5rem 1rem;
  @include bp(medium) {
    padding: 3rem 1rem 3rem;
  }
}

.footer__copy {
  display: block;
  color: $color-white;
  line-height: 1.6;
  @include font-size(14px);
}

.footer__item {
  display: block;
  margin-bottom: 0.2rem;
  @include bp(xsmall) {
    margin-bottom: 0.5rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.footer__link {
  display: inline-block;
  padding: 0.2rem 0;
  line-height: 1.4;
  color: $color-white-light;
  font-weight: 300;
  @include font-size(14px);
  @include bp(xsmall) {
    padding: 0.3rem 0;
    @include font-size(14px);
  }
  @include bp(medium) {
    @include font-size(16px);
  }
  &:hover {
    text-decoration: underline;
    color: $color-blue;
  }
}

.logo {
  display: inline-block;
}

.logo__title {
  display: block;
  position: relative;
  color: $color-white;
  @include font-size(16px);
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  &:after {
    content: '';
    display: block;
    width: 63px;
    height: 1px;
    background-color: $color-blue;
    position: absolute;
    bottom: -10px;
    left: 0;
  }
}

.logo__subtitle {
  display: block;
  margin-top: 0.8rem;
}

.logo__subtitle-text--small {
  display: inline-block;
  margin-right: 5px;
  color: #ffffff;
  font-weight: 400;
  @include font-size(10px);
}

.logo__subtitle-text {
  color: $color-white;
  @include font-size(16px);
  font-weight: 600;
}

.logo__number {
  color: $color-blue;
}
